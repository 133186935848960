export enum Skills {
  ANDROID = 'Android',
  ANGULAR = 'Angular',
  AI = 'Artificial Intelligence',
  BACKEND = 'Backend',
  BASH = 'Bash',
  C = 'C',
  C_PLUS_PLUS = 'C++',
  C_SHARP = 'C#',
  CSS = 'CSS',
  DATA_SCIENCE = 'Data Science',
  DESKTOP_APPS = 'Desktop Apps',
  DJANGO = 'Django',
  EXCEL = 'Excel',
  FIGMA = 'Figma',
  FPGA = 'FPGA',
  FRONTEND = 'Frontend',
  GAMEDEV = 'Game Development',
  GATSBY = 'Gatsby',
  HTML = 'HTML',
  IOS = 'iOS',
  JAVA = 'Java',
  JAVASCRIPT = 'Javascript',
  JIRA = 'Jira',
  JS = 'JS',
  ML = 'Machine Learning',
  MOBILE_APPS = 'Mobile Apps',
  MONGODB = 'MongoDB',
  NLP = 'Natural Language Processing',
  NEURAL_NETWORKS = 'Neural Networks',
  NEXTJS = 'Next JS',
  NODEJS = 'Node JS',
  PHP = 'PHP',
  PRODUCT_MANAGEMENT = 'Product Management',
  PYTHON = 'Python',
  REACT = 'React',
  RNN = 'RNN',
  ROBOTICS = 'Robotics',
  RUBY = 'Ruby',
  RUBY_ON_RAILS = 'Ruby on Rails',
  SKETCH = 'Sketch',
  SWIFT = 'Swift',
  TS = 'TS',
  TURING = 'Turing',
  TYPESCRIPT = 'Typescript',
  UI_DESIGN = 'UI Design',
  UNITY = 'Unity',
  UNREAL_ENGINE = 'Unreal Engine',
  UX_DESIGN = 'UX Design',
  VUE = 'Vue',
  WEB_DEV = 'Web Development',
}
export default Skills;
