// Took this from hackprinceton's website...
export const Schools: string[] = [
  '21st Century Cyber Charter School',
  'Azusa Pacific University',
  'Adventist University of Health Sciences',
  'Arizona Christian University',
  'Amherst College',
  'Ambassador University',
  'Adler School of Professional Psychology',
  'Arizona State–Polytechnic Campus',
  'Acharya Institute of Technology',
  'Antioch University–Seattle',
  'Athenaeum of Ohio',
  'Aviation Career & Technical Education High School',
  'Atlantic Cape Community College',
  'Ancaster High School',
  'Antioch University–Los Angeles',
  'Art Institute of Seattle',
  'Alma College',
  'Art Academy of Cincinnati',
  'Art Institute of Portland',
  'Associated Canadian Theological Schools',
  'American College of Education',
  'Argosy University',
  'Albertus Magnus College',
  'American Jewish University',
  'Abraham Lincoln High School - Philadelphia',
  'ABES Engineering College',
  'Associated Mennonite Biblical Seminary',
  'American River College, California',
  'Auburn University at Montgomery',
  'Algoma University College',
  'Atlantic Baptist University',
  'American University, Washington, D.C.',
  'American Heritage School',
  'Art Institute of Dallas',
  'Ambala College of Engineering and Applied Research',
  'Abilene Christian University',
  'Amrita School of Engineering',
  'Auburn University',
  'Arkansas Tech University',
  'Aalto University',
  'Albany State University',
  'Aquinas Institute of Theology',
  'ACT Academy Cyber Charter School',
  'American InterContinental University',
  'Augustana College',
  'Austin Community College District',
  'Austin Peay State University',
  'Athens State University',
  'Adams State College',
  'Aquinas College',
  'A.T. Still University of Health Sciences',
  'Acadia University',
  'Arkansas Baptist College',
  'Agora Cyber Charter School',
  'Avon High School',
  'Andover Newton Theological School',
  'Albany State University (GA)',
  'AGH University of Science and Technology',
  'American Conservatory Theater',
  'Angelo State University',
  'American College of Acupuncture & Oriental Medicine',
  'Abraham Lincoln High School',
  'Andover Central High School',
  'Alice Lloyd College',
  'American Public University',
  'Albright College',
  'Amberton University',
  'Amity University',
  'Appalachian Bible College',
  'Asbury Theological Seminary',
  'American International College',
  'Aarhus University',
  'Aston University',
  'Ave Maria University',
  'Alderson-Broaddus College',
  'Antioch University–New England',
  'Antioch University–Santa Barbara',
  'Adrian College',
  'Armstrong Atlantic State University',
  'American University',
  'Assumption University',
  'Anchorage',
  'Abbey Park High School',
  'Albion College',
  'Advanced Math and Science Academy Charter School',
  'Abertay University',
  'Athabasca University',
  'Allen High School',
  'American University of Puerto Rico',
  'Alfred State University',
  'Alaska Bible College',
  'American Military University',
  'Allen University',
  'Ashford University',
  'Alaska Pacific University',
  'Academy of Technology',
  'Arizona State University–Downtown Phoenix Campus',
  'Alfred University',
  'Anchor Bay High School',
  'Adler Graduate School',
  'Aguadilla Campus',
  'Alabama A&M University',
  'Arecibo Campus',
  'Augsburg College',
  'American Film Institute',
  'AIB College of Business',
  'Albany College of Pharmacy & Health Sciences',
  'Art Institute of Pittsburgh',
  'Acardia High School, Arizona',
  'Academy of Art University',
  'Alvernia University',
  'Aurora University',
  'Adelphi University',
  'Alverno College',
  'American Indian College of the Assemblies of God',
  'Ashland University',
  'Austin Graduate School of Theology',
  'Anderson University',
  'Augustana University College',
  'Andrews University',
  'Art Institute of Atlanta',
  'Albany',
  'Acton-Boxborough Regional High School',
  'Antioch College',
  'Art Center College of Design',
  'Academy at Palumbo',
  'Abington Senior High School',
  'AOMA Graduate School of Integrative Medicine',
  'Arizona State University',
  'Arcadia University',
  'Alfa College',
  'Amridge University',
  'American Academy of Art',
  'Asbury University',
  'Austin Presbyterian Theological Seminary',
  'American University in Dubai',
  'Appalachian State University',
  'Alliant International University',
  'Antioch University–Midwest',
  'Anna Maria College',
  'Atlanta Metropolitan State College',
  'Arkansas State University',
  'Agnes Scott College',
  'Alcorn State University',
  'Austin College',
  'Air University',
  'Assumption College',
  'Allen College',
  'Air Force Institute of Technology',
  'Arcadia High School, California',
  'American High School',
  'Avila University',
  'Abraham Baldwin Agricultural College',
  'Abbey Park Middle School',
  'Art Institute of Houston',
  'Art Institute of Philadelphia',
  'ASPIRA Bilingual Cyber Charter School',
  'Alabama State University',
  'Anna University',
  'Adlai E. Stevenson High School',
  'Averett University',
  'Achievement House Charter School - Online',
  'Alameda High School',
  'Art Institute of Colorado',
  'Albany Medical College',
  'Arlington Baptist College',
  'Arizona State University–West Campus',
  'Allegheny College',
  'Blue Mountain Academy',
  'Bellevue College',
  'Birmingham-Southern College',
  'Boricua College',
  'Babson College',
  'Bethany College',
  'Bloomsburg University of Pennsylvania',
  'Biblical Theological Seminary',
  'Bennington College',
  'Brevard College',
  'Barry University',
  'Brenau University',
  'Benjamin Franklin Institute of Technology',
  'Briarcliffe College',
  'Baptist Missionary Association Theological Seminary',
  'Brandon University',
  'Baptist Bible College & Seminary',
  'Benjamin Franklin High School - Baltimore',
  'Barranquitas Campus',
  'Brigham Young University',
  'Bethesda University of California',
  'Boys Latin of Philadelphia Charter School',
  'Battlefield High School',
  'Bryn Athyn College',
  'Brescia College',
  'Boca Raton Community High School',
  'Bluffton University',
  'Brooklyn College, CUNY',
  'Bennett College for Women',
  'Baptist Memorial College of Health Sciences',
  'Binghamton University',
  'Berklee College of Music',
  'Brookwood High School',
  'British Columbia Institute of Technology',
  'Brampton Centennial Secondary School',
  'Brown University',
  'Brookdale Community College',
  'Bismark State College',
  'Bryant University',
  'Boston University',
  'Broward College',
  'Baldwin-Wallace College',
  'Bastyr University',
  'Bayside High School',
  'Bryn Mawr College',
  'Brescia University ',
  'Bowie State University',
  'Belmont University',
  'Bethel College',
  'Bellevue University',
  'Bryan College of Health Sciences',
  'Babaria Institute of Technology',
  'Blinn College',
  "Bard College at Simon's Rock",
  'Bangor Theological Seminary',
  'Brno University of Technology',
  'Bethel University',
  'Bayview Secondary School',
  'Barclay College',
  'Bard College',
  'Bucks County Community College',
  'Bloomfield Hills High School',
  'Bethune-Cookman University',
  'Baton Rouge Community College',
  'Blue Mountain College',
  'Beihang University',
  'Beloit College',
  'Belhaven University',
  'Baylor University',
  'Bemidji State University',
  'Bellin College',
  'Business Academy Aarhus',
  'Bluefield State College',
  'Bethany Theological Seminary',
  'Barton College',
  'Baker College',
  'BMIIT, Uka Tarsadia University, Bardoli, Surat',
  'Bowdoin College',
  'Baptist Bible College',
  'Brooklyn Technical High School',
  'Bergen County Technical High School - Teterboro',
  'Brandeis University',
  'Biola University',
  'Bucknell University',
  'Blackburn College',
  'Buffalo State College',
  'Bournemouth University',
  'Birmingham City University',
  'Bellevue College, Washington',
  'Boston Conservatory',
  'Baruch College',
  'Borough of Manhattan Community College, CUNY',
  'Buffalo',
  'British Columbia',
  'Bacone College',
  'Baylor College of Medicine',
  'Bloomfield College',
  'Beulah Heights University',
  'Buena Vista University',
  'Baruch College, CUNY',
  'Berry College',
  'Black Hills State University',
  'Bentley University',
  'Barnard College',
  'Bergen Community College',
  'Bergen Catholic High School',
  'Binghamton',
  'Becker College',
  'Bluevale Collegiate Institute',
  'Benha University',
  'Ball State University',
  'Berea College',
  'Boston Graduate School of Psychoanalysis',
  'Benedict College',
  'Bellarmine University',
  'Belmont Abbey College',
  'Burlington College',
  'Bridgewater College',
  'Bryan College',
  'Brock University',
  'Benedictine College',
  'Bank Street College of Education',
  'Bharathiar University',
  'Boston College',
  'Boise State University',
  'Bay State College',
  'Brandman University',
  'Benjamin Franklin High School - Philadelphia',
  'Bay Path College',
  'Brentsville High School',
  'Boston Architectural College',
  'Brigham Young University–Hawaii',
  'Bilkent University',
  'Bergen County Academies',
  'Bauder College',
  'Berkeley College',
  'Brooklyn College',
  'Bradley University',
  'Bluefield College',
  'Bayamón Campus',
  'Bethel University & Seminary',
  'Boston Latin School',
  'Butler University',
  'Bourne Grammar School',
  "Bishop's University",
  'Bronx Community College, CUNY',
  'Baker University',
  'Blessing-Rieman College of Nursing',
  'Bowling Green State University',
  'Brewton-Parker College',
  'Boston University Metropolitan College',
  'Briar Cliff University',
  'Bridgewater State University',
  'Beacon College',
  'Brigham Young University–Idaho',
  'BlueCrest University College',
  'Birkbeck, University of London',
  'Bates College',
  'Brite Divinity School',
  'Berkshire Community College',
  'Bethany Lutheran College',
  'Benedictine University',
  'College of Mount Saint Joseph',
  'Central Christian College of the Bible',
  'Cox College',
  'City College',
  'California State University, San Jose',
  'College of the Menominee Nation',
  'Concordia University–Wisconsin',
  'California Institute of the Arts',
  'Collège Jean-de-Brébeuf',
  'College of Biblical Studies–Houston',
  "Crowley's Ridge College",
  'College of Agriculture & Technology at Cobleskill',
  'Colorado School of Mines',
  'Central Peel Secondary School',
  'Carthage College',
  'California State University System',
  'Concordia College–Alabama',
  'Council Rock High School South',
  'Capilano College',
  'California State University, San Bernardino',
  'College of Mount Saint Vincent',
  'College Dominicain',
  'Caldwell University',
  'Cincinnati Christian University',
  'Collège de Bois-de-Boulogne',
  'Claflin University',
  'College of Southern Nevada',
  'Chapman University',
  'Chicago Theological Seminary',
  'College at Oneonta',
  'Concordia University–Ann Arbor',
  'Carleton University',
  'Clearwater Christian College',
  'College at Potsdam',
  'Caldwell College',
  'Cedar Creek High School',
  'Clear Creek Baptist Bible College',
  'Coker College',
  'College at New Paltz',
  'Concord University',
  'College of Staten Island, CUNY',
  'California State University, Channel Islands',
  'Central Christian College of Kansas',
  'Chaminade University',
  'Colorado Technical University',
  'College of Notre Dame of Maryland',
  'California State University, Fullerton',
  'Camden County College',
  'Centenary College of Louisiana',
  'California Polytechnic State University, San Luis Obispo',
  'Crown College',
  'Cathedral High School, Los Angeles',
  'CAPA - Philadelphia High School for Creative and Performing Arts',
  'Cleveland State University',
  'Colorado Christian University',
  'Carroll College',
  'Carson-Newman College',
  'Concordia University–Portland',
  'California Northstate University',
  'Caribbean University',
  'California State University, San Francisco',
  'California Institute of Technology',
  'College of Saints John Fisher & Thomas More',
  'Cupertino High School',
  'Conception Seminary College',
  'Corcoran College of Art & Design',
  'Community College of Philadelphia',
  'Colby College',
  'Concordia University–Texas',
  'Conservatorio de Música de Puerto Rico',
  'Centro de Enseñanza Técnica y Superior (CETYS), Campus Ensenada',
  'College at Plattsburgh',
  'College of the Ozarks',
  'Calvary Baptist Seminary',
  'Centennial High School',
  'California State University–Dominguez Hills',
  'California State University, Northridge',
  'Colgate University',
  'Clark Atlanta University',
  'Century College',
  'Clarion University of Pennsylvania',
  'Cardinal Stritch University',
  'Cincinnati College of Mortuary Science',
  'Christ the King Seminary',
  'College at Fredonia',
  'Centro de Enseñanza Técnica y Superior (CETYS), Campus Mexicali',
  'Capital University',
  'California State University, Humboldt',
  'Chancellor University',
  'Collège Jean-Eudes',
  'Chipola College',
  'California State University–Monterey Bay',
  'Conestoga High School',
  'Church Divinity School of the Pacific',
  'College at Cortland',
  'Christian Brothers University',
  'Clark University',
  'Comenius University',
  'College at Oswego',
  'Centre College',
  'Cabarrus College of Health Sciences',
  'California State University–San Bernardino',
  'Conway School of Landscape Design',
  'Central PA Digital Learning Foundation Charter School',
  'California State University–Chico',
  'Cincinnati State Technical and Community College',
  'Centenary College',
  'College of Wooster',
  'Council Rock High School North',
  'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
  'Concordia University–Saint Paul',
  'Culver-Stockton College',
  'Columbia College of Nursing',
  'Cedar Crest College',
  'Clayton State University',
  'Clarke University',
  'Cherokee High School',
  'Coventry University',
  'College of Staten Island',
  'Community Academy of Philadelphia Charter School',
  'California High School',
  'Cornish College of the Arts',
  'California State University–Long Beach',
  'Collège Français',
  'Concordia University–Irvine',
  'Concordia University–Nebraska',
  'C. D. Hylton High School',
  'Cégep André-Laurendeau',
  'Community College of Baltimore County',
  'Cégep de Saint-Laurent',
  'Catawba College',
  'Central Texas College',
  'Cambridge College ',
  'Collège Militaire Royal',
  'Chestnut Hill College',
  'College of the Rockies',
  'Columbia College Chicago',
  'Columbia Centro Universitario',
  'Chaparral Star Academy',
  'California State University, San Marcos',
  'College of the Holy Cross',
  'Carlow University',
  'Chicago State University',
  'California State University, Bakersfield',
  'Conestoga College',
  'Carteret High School',
  'California State University–Fresno',
  'City University London',
  'California State University–East Bay',
  'Chalmers University of Technology',
  'College of William and Mary',
  'Carlos Albizu University',
  'Colorado State University',
  'College of Saint Benedict',
  'Concordia College–Moorhead',
  'Crossroads College',
  'Columbia Secondary School',
  'Capitol College',
  'California State University–Stanislaus',
  'Covenant Theological Seminary',
  'College for Financial Planning',
  'Corban University',
  'Conroe ISD Academy of Science and Technology, Texas',
  'Canadian College of Naturopathic Medicine',
  'Cerritos College',
  'Claremont Graduate University',
  'Central Washington University',
  'Cameron University',
  'Cascadia College',
  'Canyon Crest Academy',
  'Collège Universitaire de St.-Boniface',
  'California Institute of Integral Studies',
  'College of Agriculture & Technology at Morrisville (Morrisville State College)',
  'Calvary Bible College & Theological Seminary',
  'Claremont School of Theology',
  'Cedarville University',
  'Charleston Southern University',
  'Carroll University',
  'Colegio Universitario de San Juan',
  'Cleveland Institute of Music',
  'California State University, Stanislaus',
  'California State University–Fullerton',
  'California State University, Los Angeles',
  'College of Technology at Delhi',
  'Catholic Theological Union',
  'College at Purchase',
  'Cornell University',
  'Calvin College',
  'Charles R. Drew University of Medicine & Science',
  'Chester College of New England',
  'Canada (Cañada) College',
  'California State University, Fresno',
  'Connecticut College',
  'Collège Lionel-Groulx',
  'Collège de Montréal',
  'City Neighbors High School',
  'Concordia University',
  'Collège André-Grasset',
  'California State University, Long Beach',
  'Columbia College',
  'College at Old Westbury',
  'Central Baptist Theological Seminary',
  'Chowan College',
  'Columbus State University',
  'Concordia Seminary–Saint Louis',
  'Coquitlam College',
  'Christopher Newport University',
  'COMSATS Institute of Information Technology',
  'Coral Glades High School',
  'Channabasaveshwara Institute of Technology',
  'Cedar Ridge High School',
  'Canadian University College',
  'Cégep du Vieux Montréal',
  'Chattahoochee Technical College',
  'Camosun College',
  'Collins Hill High School',
  'Central College',
  'Coastal Carolina University',
  'Columbia University',
  'Collège de Rosemont',
  'College of Saint Rose',
  'Central Penn College',
  'Criswell College',
  'Colegio Simón Bolívar',
  'Colby-Sawyer College',
  'Champlain College',
  'Central Michigan University',
  'Claremont Consortium of Colleges',
  'California State University–Los Angeles',
  'Cornerstone University',
  'Concordia College–New York',
  'Columbus College of Art and Design',
  'Central High School - Phialdelphia',
  'California State University–Channel Islands',
  'Columbus State Community College',
  'College of New Rochelle',
  'Capella University',
  'Cooper Union',
  'Colleyville Heritage High School',
  'Chinguacousy Secondary School',
  'Cherry Hill High School East',
  'College of Saint Joseph',
  'Cornell College',
  'Concordia University College of Alberta',
  'Cottey College',
  'Cadbury Sixth Form College',
  'Collège de Maisonneuve',
  'Concord Academy',
  'Canadian Memorial Chiropractic College',
  'Columbus College of Art & Design',
  'Covenant College',
  'Concordia University–Chicago',
  'California College of the Arts',
  'California State University–Northridge',
  'Cleary University',
  'Carlos Albizu University–Miami Campus',
  'Central Methodist University',
  'Columbia Theological Seminary',
  'Campion College',
  'California State University–Sacramento',
  'California State University, Chico',
  'Colorado College',
  'Cazenovia College',
  'Canadian Mennonite Bible College',
  'College of Environmental Science & Forestry',
  'Cumberland University',
  'City University of Seattle',
  'Cégep Marie-Victorin',
  'Cranbrook Academy of Art',
  'Cardiff Metropolitan University',
  'California State University–San Marcos',
  'Cherry Hill High School West',
  'Central Connecticut State University',
  'Chamberlain College of Nursing',
  'Cairn University',
  'Claremont McKenna College',
  'Conrad Grebel College',
  'Chadron State College',
  'College of the Atlantic',
  'College of Engineering, Pune',
  'Calumet College of Saint Joseph',
  'California State Polytechnic University, Pomona',
  'Creighton University',
  'College of Charleston',
  'Creekview High School',
  'California State University, Monterey Bay',
  'California State University, San Diego',
  'Community College of Allegheny County',
  'Collège Ahuntsic',
  'Cabrini College',
  'Cogswell Polytechnical College',
  'County College of Morris',
  'California State University, Dominguez Hills',
  'Concord College',
  'Clemson University',
  'Colorado Mesa University',
  'California State University, San Luis Obispo',
  'California State University, Sacramento',
  'Castleton State College',
  'Charter Oak State College',
  'Canisius College',
  'Charter High School for Architecture and Design - Philadelphia',
  'Culinary Institute of America',
  'California State University, Sonoma',
  'Centennial Collegiate Vocational Institute',
  'College of Optometry',
  'Canadian Bible College and Theological Seminary',
  'College of Visual Arts ',
  'Cleveland Institute of Art',
  'Converse College',
  'Cheyney University',
  'College of Central Florida',
  'California Maritime Academy',
  'California State University, Maritime',
  'Concordia College–Bronxville',
  'College of Saint Elizabeth',
  'Carleton College',
  'Cumberland County College',
  'Clarksburg High School',
  'Covenant University',
  'California University of Pennsylvania',
  'Columbia International University',
  'Carnegie Mellon University',
  'Christendom College',
  'Case Western Reserve University',
  'Collège Regina Assumpta',
  'College at Geneseo',
  'Commonwealth Charter Academy Charter School',
  'California Lutheran University',
  'College for Creative Studies',
  'Coppin State University',
  'Clarkson College',
  'Cranfield University',
  'Community College of Rhode Island',
  'Citrus College',
  'Campbellsville University',
  'Cranbrook Schools',
  'Chapel Hill High School',
  'College of Westchester',
  'Central Baptist College',
  'College of Technology at Canton',
  'College of Saint Mary',
  'College of Coastal Georgia',
  'Central State University',
  'Campbell University',
  'Curry College',
  'Christian Theological Seminary',
  'Concordia Theological Seminary',
  'Coe College',
  'California Baptist University',
  'California State University, East Bay',
  'College of Saint Scholastica',
  'California State University–Bakersfield',
  'Curtis Institute of Music',
  'Colorado State University–Pueblo',
  'Cleveland Chiropractic College',
  'Cabrini University',
  'Cameron Heights Collegiate Institute',
  'Constitution High School - Philadelphia',
  'Clarkson University',
  'College at Brockport',
  'Dallas Christian College',
  'Delaware Technical Community College',
  'DeVry Institute of Technology',
  'Dublin Jerome High School',
  'DeVry University–Arlington',
  'Drew University',
  'Divine Word College',
  'D´Youville College',
  'Durham University',
  'Donnelly College',
  'Delaware County Community College - Sharon Hill',
  'Daniel Webster College',
  'Dowling College',
  'Davidson College',
  'Delta State University',
  'DeVry University',
  'Diablo Valley College',
  'Des Moines Area Community College',
  'Dwight-Englewood School',
  'Durham College',
  'DeVry University–Long Beach',
  'DeVry University–Kansas City',
  'Dickinson State University',
  'Delaware Valley Academy of Medical and Dental Assistants',
  'Dougherty Valley High School',
  'Dordt College',
  'Dominican School of Philosophy & Theology',
  'Denison University',
  'Duquesne University',
  'Delaware Valley University',
  'De Anza College',
  'Delaware County Community College - Exton',
  'Dickinson College',
  'DePaul University',
  'Dakota Wesleyan University',
  'Dillard University',
  'Downstate Medical Center',
  'Dunwoody College of Technology',
  'Delaware County Community College - West Grove',
  'Durant High School',
  'Digital Harbor High School',
  'Dakota State University',
  'Denver Seminary',
  'DeVry University–Addison',
  'Dharmsinh Desai University',
  'Davenport University',
  'Dulaney High School',
  'DeVry University–Pomona',
  'Dalhousie University',
  'Delaware County Community College - Main Campus (Marple)',
  'Delaware County Community College - Phoenixville',
  'Delft University of Technology',
  'Dublin High School',
  'Dominican House of Studies',
  'DeVry University–Phoenix',
  'Des Moines University–Osteopathic Medical Center',
  'Dallas Theological Seminary',
  'Delaware County Community College - Downingtown',
  'Dawson College',
  'Delaware Valley College',
  'Duke University',
  'DeVry University–Chicago',
  'DeVry University–Irving',
  'DeVry University–Georgia',
  'Douglas College',
  'Daemen College',
  'Dominican College',
  'Davis College',
  'Delhi Technological University',
  'Dr. B. R. Ambedkar National Institute of Technology Jalandhar',
  'Deerfield High School',
  'DeVry University–Orlando',
  'DePauw University',
  'Dean College',
  'Doane College',
  'Drexel University',
  'Dominican University of California',
  'Dalton State College',
  'Drury University',
  'Dixie State University',
  'Drake University',
  'Delaware State University',
  'Devry University - Philadelphia Center City',
  'Daytona State College',
  'Del Norte High School',
  'Dartmouth College',
  'Dallas Baptist University',
  'Defiance College',
  'Dwarkadas J. Sanghvi College of Engineering',
  'Davis & Elkins College',
  'Dominican University',
  'DalTech (Technical University of Nova Scotia)',
  'Delaware County Community College - Upper Darby',
  'DeSales University',
  'Elizabeth High School',
  'Edinboro University of Pennsylvania',
  'Eastern Michigan University',
  'ECPI University',
  'Elon University',
  'East Chapel Hill High Schoo',
  'Earl of March Secondary School',
  'Erasmus Hogeschool Brussel',
  'Eastern New Mexico University',
  'Eureka College',
  'Eden Theological Seminary',
  'Elmhurst College',
  'Emporia State University',
  'Edward Waters College',
  'Erikson Institute',
  'Endicott College',
  'Eastern Pentecostal Bible College',
  'Everglades University',
  'East Tennessee State University',
  'Edward R. Murrow High School',
  'Eastern Virginia Medical School',
  'El Centro College',
  'Erindale College',
  'East Carolina University',
  'Esperanza Academy Charter School',
  'Eastern University - St. Davids',
  'Eastern Washington University',
  'Elgin Academy',
  'Elmira College',
  'Eastern Nazarene College',
  'Empire State College',
  'Emmaus Bible College',
  'Edgewood College',
  'Evangel University',
  'Eastern Kentucky University',
  'Edina High School',
  'Emmanuel College',
  'Emory University',
  'Eastern University',
  'EPFL | École polytechnique fédérale de Lausanne',
  'Ernest Manning High School',
  'Eastern Illinois University',
  'Elizabethtown College',
  'Emmanuel Christian Seminary',
  'East Los Angeles College',
  'Earlham College',
  'El Centro de Estudiantes',
  'Edison High School',
  'Ecole des Hautes Etudes Commerciales',
  'Eidgenössische Technische Hochschule (ETH) Zürich',
  'Erie Community College',
  'Embry-Riddle Aeronautical University',
  'Emory & Henry College',
  'Evergreen Valley High School',
  'Eastern Regional High School',
  'East Texas Baptist University',
  'East Brunswick High School',
  'Eastern High School - Louisville',
  'Edinburgh Napier University',
  'Evergreen Valley College',
  'EDP College',
  'Escuela de Artes Plásticas de Puerto Rico',
  'Ecole Polytechnique',
  'Emerson College',
  'Evangelical Theological Seminary',
  'Excelsior College',
  'Eckerd College',
  'Eastern Florida State College',
  'Esperanza Cyber Charter School',
  'Edmundston (Saint-Louis-Maillet)',
  'Emily Carr Institute of Art and Design',
  'El Camino College',
  'EASTERN Center for Arts and Technology',
  'Eastern Mennonite University',
  'EPITECH Bordeaux',
  'Eastern University Academy Charter School',
  'East Stroudsburg State University of Pennsylvania',
  'Erskine College',
  "Ecole Nationale d'Administration Publique",
  'Ecole de Technologie Superieure',
  'Eastern Oregon University',
  'Evergreen State College',
  'Eastern Connecticut State University',
  'East Central University',
  'East-West University',
  'Elms College',
  'Elizabeth City State University',
  'Forest Institute of Professional Psychology',
  'Foothill College',
  'Fort Hays State University',
  'Future Generations Graduate School',
  'Florida Memorial University',
  'Fashion Institute of Design & Merchandising',
  'Furman University',
  'Francis Holland School',
  'Fayetteville State University',
  'Florida Southern College',
  'Fairbanks',
  'Full Sail University',
  'Faith Baptist Bible College & Theological Seminary',
  'Florida Agricultural & Mechanical University',
  'Franklin College',
  'Florida SouthWestern State College',
  'Fisk University',
  'Freed-Hardeman University',
  'Felician College',
  'Frankford High School - Philadelphia',
  'Fr. Conceicao Rodrigues College of Engineering',
  'Florida Polytechnic University',
  'Fahaheel Al-Watanieh Indian Private School',
  'Franciscan University of Steubenville',
  'Francis Lewis High School',
  'Ferrum College',
  'Faulkner University',
  'Five Towns College',
  'Franklin W. Olin College of Engineering',
  'Fairleigh Dickinson University',
  'Florida State University',
  'Franklin Learning Center - Phialdelphia',
  'First Philadelphia Preparatory Charter School',
  'Fort Valley State University',
  'Forest Park High School - Forest Park, GA',
  'Forest Park High School - Woodbridge',
  'FernUniversität in Hagen',
  'Freehold High School',
  'Fashion Institute of Technology',
  'Franklin Pierce University',
  'Fajardo Campus',
  'Fielding Graduate University',
  'Florida Gulf Coast University',
  'Fremont High School',
  'Ferris State University',
  'Franklin & Marshall College',
  'Fairfield University',
  'Florida Institute of Technology',
  'Forest Park High School - Baltimore',
  'Fachhochschule Dortmund',
  'Florida Institute Of Technology',
  'Framingham State University',
  'Francis Marion University',
  'Franciscan School of Theology',
  'Flagler College',
  'Fairmont State University',
  'Franklin Towne Charter High School',
  'Frederick Community College',
  'Fontbonne University',
  'Fairview High School',
  'Florida College',
  'Fullerton College',
  'Florida State College at Jacksonville',
  'Florida Atlantic University',
  'Farmingdale State College',
  'Florida Agricultural & Mechanical (A&M) University',
  'Florida National University',
  'Fordham University',
  'Frostburg State University',
  'Franklin University',
  'Finlandia University',
  'Franklin High School',
  'Florida International University',
  'Frank Lloyd Wright School of Architecture',
  'Freedom High School - Woodbridge',
  'Fuller Theological Seminary',
  'Fresno Pacific University',
  'Fisher College',
  'Fitchburg State University',
  'Fort Scott Community College',
  'Fort Lewis College',
  'Freire Charter High School',
  'Friends University',
  'Forest Heights Collegiate Institute',
  'Fontys Hogeschool',
  'Frontier Nursing University',
  'Freedom High School - Bethlehem',
  'Guru Gobind Singh Indraprastha University',
  'Glenville State College',
  'Gettysburg College',
  'Green River College',
  "Governor's School for Science & Technology",
  'Global University',
  'George C. Marshall High School',
  'Grand Valley State University',
  'Glendale Community College',
  'Graduate School & University Center',
  'Glenaeon Rudolf Steiner School',
  'Glassboro High School',
  'Goucher College',
  'George Washington University',
  'Goldey-Beacom College',
  'Grand Canyon University',
  'Goodwin College',
  'Garrett-Evangelical Theological Seminary',
  'Grand View University',
  'George Washington High School - Philadelphia',
  'Geroge Washington Carver High School - Philadelphia',
  "George Heriot's School",
  'Grady High School',
  'Graduate Institute of Applied Linguistics',
  'Greensboro College',
  'Goddard College',
  'Ganga International School',
  'Gujarat Technological University',
  'Graduate Theological Union',
  'Ganpat University',
  'Garnet Valley High School',
  'Granite State College',
  'Georgia College & State University',
  'Gratz College',
  'George Washington University–Virginia Science & Technology Campus',
  'Gwynedd Mercy University',
  'Glenbrook South High School',
  'Georgia Institute of Technology',
  'Golden Gate University',
  'Georgetown University',
  'Georgia Southern University',
  'George Fox University',
  'GIDC Degree Engineering College',
  'Glenforest Secondary School',
  'Guayama Campus',
  'Greenville College',
  'Guilford College',
  'Georgetown College',
  "God's Bible School & College",
  'Grant MacEwan Community College',
  'Georgia Gwinnett College',
  'Guttman Community College, CUNY',
  'Gordon-Conwell Theological Seminary',
  'Gonzaga University',
  'Greenwood College School',
  'Governors State University',
  'Gujarat University',
  'Gallaudet University',
  'Grace University',
  'Gannon University',
  'Georgia State University',
  'Government Model Engineering College, Thrikkakara',
  'Green Mountain College',
  'Gordon State College',
  'Germantown Friends School',
  'Great Lakes Christian College',
  'Great Basin College',
  'Gardner-Webb University',
  'Gustavus Adolphus College',
  'Goldfarb School of Nursing at Barnes-Jewish College',
  'Gulf Coast State College',
  'Grace Bible College',
  'Glendon College',
  'Gottfried Wilhelm Leibniz Universität Hannover',
  'Greater Lowell Technical High School',
  'Guelph Collegiate Vocational Institute',
  'Georgia Regents University',
  'Golden Gate Baptist Theological Seminary',
  'Grinnell College',
  'Grande Prairie Regional College',
  'Georgia Southwestern State University',
  'George Mason University',
  'Geneva College',
  'Gordon College',
  'Gordon Graydon Memorial Secondary School',
  'Graceland University',
  'Grace College & Seminary',
  'Grand Rapids Community College',
  'Gar-Field Senior High School',
  'Goldsmiths, University of London',
  'Grambling State University',
  'Goshen College',
  'Glenbrook North High School',
  'Georgian Court University',
  'Ghent University',
  'Grove City College',
  'Helwan University',
  'Hazleton Area High School',
  'Highland Park High School',
  'Holy Apostles College & Seminary',
  'Harvard Medical School',
  'Huntingdon College',
  'Hawaii Pacific University',
  'Hastings College',
  'Huntington University',
  'Hampton University',
  'Holton-Arms School',
  'Harrington College of Design',
  'Holy Cross College',
  'High Technology High School',
  'Herzing University',
  'Houston Community College',
  'Hostos Community College, CUNY',
  'Hope International University',
  'H.N. Werkman College',
  'Hussian School of Art',
  'Hebrew Union College–Jewish Institute of Religion',
  'Hazelden Graduate School of Addiction Studies',
  'Hinsdale Central High School',
  'Head-Royce School',
  'Harper College',
  'Hendrix College',
  'Homestead High School',
  'Hampshire College',
  'Hult International Business School',
  'Hartford Seminary',
  'Hong Kong University of Science and Technology',
  'Huron College',
  'Hartwick College',
  'Houghton High School',
  'Hillsborough Community College',
  'Husson University',
  'Herguan University',
  'Harvard University',
  'Hamline University',
  'Howard Payne University',
  'Hillsborough High School',
  'Heidelberg College',
  'Hope College',
  'Hofstra University',
  'Haskell Indian Nations University',
  'High Point University',
  'Harcum College',
  'Hellenic College/Holy Cross Greek Orthodox School of Theology',
  'Hebrew Theological College',
  'Harrisburg University of Science & Technology',
  'Henderson State University',
  'Hood College',
  'Hunter College High School',
  'Holy Family University',
  'Hiram College',
  'Hollins University',
  'Hilbert College',
  'Houston Baptist University',
  'Harding University',
  'Hasso-Plattner-Institut Academy',
  'HAN University of Applied Sciences',
  'Hebrew College',
  'Health Careers High School',
  'Hudson Valley Community College',
  'Horace Furness High School',
  'Hudson County Community College',
  'Harrison Career Institute',
  'Howard University',
  'Huston-Tillotson University',
  'Hanze University of Applied Sciences',
  'Holy Names University',
  'Hillsdale College',
  'Hogeschool Thomas More',
  'Het Baarnsch Lyceum',
  'Henry M. Gunn High School',
  'Huron Heights Secondary School',
  'Humboldt State University',
  'Houghton College',
  'Hobart & William Smith Colleges',
  'Harvey Mudd College',
  'Hamilton College',
  'Hodges University',
  'Haverford College',
  'Hannibal-Lagrange College',
  'Heritage Institute of Technology',
  'Hardin-Simmons University',
  'Harris-Stowe State University',
  'Hanover College',
  'Humphreys College',
  'Heritage University',
  'Haaga-Helia University of Applied Sciences',
  'Hogeschool van Amsterdam',
  'Hobe Sound Bible College',
  'Hampden-Sydney College',
  'Hunter College',
  'Hunter College, CUNY',
  'Horace Mann School',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Irapuato',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad de Mexico',
  'International Institute Of Information Technology-Naya Raipur',
  'Imhotep Institute Charter High School',
  'Immaculata University',
  'Indiana University',
  'Instituto Tecnológico Superior de los Ríos',
  'Illinois Institute of Art',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Saltillo',
  'Indiana University-Purdue University Fort Wayne',
  'Indian Institute of Technology Madras',
  'Irell & Manella Graduate School of Biological Sciences',
  'Iowa Wesleyan College',
  'Illinois Institute of Art–Schaumburg',
  'Institut National de la Récherche Scientifique',
  'Indiana University/Purdue University at Columbus',
  'Indian School of Mines, Dhanbad',
  'Institute of American Indian Arts',
  'Indiana University–South Bend',
  'Indiana University System',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Lagüera',
  'Instituto Tecnólogico de La Laguna (ITL)',
  'Indian Institute of Technology Jodhpur',
  'Instituto Tecnologico Superior de San Martin Texmelucan',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Morelia',
  'Indian Institute of Technology Gwalior',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Tampico',
  'Institute for Clinical Social Work',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Querétaro',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Catarina',
  'Indiana University–Northwest',
  'Ithaca College',
  'Instituto Tecnológico Superior de Cintalapa',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cuernavaca',
  'Indiana University of Pennsylvania',
  'Iowa Western Community College',
  'Illinois Institute of Technology',
  'Iowa State University',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chihuahua',
  'Illinois College of Optometry',
  'Indiana University–Southeast',
  'Iowa Central Community College',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Guadalajara',
  'Indian Institute of Technology Roorkee',
  'Iliria College',
  'International Institute of Information Technology-Hyderabad',
  'Indiana University–Bloomington',
  'Idaho State University',
  'Insight PA Cyber Charter School',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Obregón',
  'Indira Gandhi Delhi Technical University for Women',
  'Indian Hills Community College',
  'Illinois Wesleyan University',
  'International School of Choueifat',
  'Iliff School of Theology',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad Juárez',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Hidalgo',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Puebla',
  'Illinois State University',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sonora',
  'Indian Institute of Technology Bombay',
  'Institute of Technology at Utica/Rome',
  'Indian Institute of Technology Kharagpur',
  'Indira Gandhi National Open University',
  'Institut polytechnique de Bordeaux (INP)',
  'Indian Institute of Technology Guwahati',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Aguascalientes',
  'Icahn School of Medicine at Mount Sinai',
  'IT University of Copenhagen',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus San Luis Potosí',
  'Indian Institute of Technology Bhubaneswar',
  'Instituto Tecnológico Superior de El Mante',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Fe',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Veracruz',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Zacatecas',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Laguna',
  'Institute for the Psychological Sciences',
  'Indiana Institute of Technology',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus León',
  'Instituto Politécnico Nacional',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cumbres',
  'Illinois College',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chiapas',
  'Indiana Wesleyan University',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Valle Alto',
  'Indian Institute of Technology Gandhinagar',
  'Interdenominational Theological Center',
  'Istanbul University',
  'Instytut Pamięci Narodowej',
  'I.T.S Engineering College',
  'Indian Institute of Technology Hyderabad',
  'Iona College',
  'ITMO University',
  'Indiana University–Kokomo',
  'International Leadership Charter High School',
  'Indian Institute of Technology Kanpur',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Toluca',
  'Indiana University–East',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Sada',
  'Indiana University/Purdue University at Indianapolis',
  'Indian River State College',
  'Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO)',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sinaloa',
  'Institute of World Politics',
  'Indiana State University',
  'Indiana University/Purdue University at Fort Wayne',
  'Indiana University–Purdue University Indianapolis',
  'Institut Armand-Frappier',
  'Institute of Engineering and Rural Technology Allahabad',
  'Indian Institute of Technology Ropar',
  'Indian Institute of Technology Kota',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
  'Imperial College London',
  'Indraprastha Institute of Information Technology',
  'Indian Institute of Technology Allahabad',
  'Indian Institute of Technology Jabalpur',
  'Indian Institute of Engineering Science and Technology Shibpur',
  'Jalpaiguri Government Engineering College',
  'Jacksonville University',
  'Jules E. Mastbaum Technical High School',
  'Jaipur National University',
  'James Madison University',
  'Jarvis Christian College',
  'John Jay College of Criminal Justice, CUNY',
  'Jones International University',
  'Johnson University Florida',
  'John A. Ferguson Senior High School',
  'John P. Stevens High School',
  'Jacobs University Bremen',
  'Johnson & Wales University',
  'Julia R. Masterman School',
  'Jagiellonian University',
  'James Madison High School',
  'John Carroll University',
  'Johnson State College',
  "James Gillespie's High School",
  'John F. Kennedy University',
  'John Marshall Law School',
  'Juniata College',
  'Johns Hopkins University',
  'John Brown University',
  'Jaypee Institute Of Information Technology',
  'Jackson State University',
  'Johnson C. Smith University',
  'John Jay College of Criminal Justice',
  'John F. Kennedy Memorial High School',
  'John Leggott College',
  'Judson College',
  'Judson University',
  'Jefferson College of Health Sciences',
  'John Abbott College',
  'Jacksonville State University',
  'Johnson University',
  'John Bartram High School',
  'Kendall College of Art & Design of Ferris State University',
  'KIPP DuBois Charter School',
  'Kentucky State University',
  'Kent State University–Tuscarawas',
  'Knox College',
  'KTH Royal Institute of Technology',
  'Knowledge Systems Institute',
  'Kansai University',
  'Kansas City Art Institute',
  'Kraków University of Economics',
  'Kingston College',
  'Kent State University at Stark',
  'Keystone College',
  'Kingsborough Community College, CUNY',
  'Kutztown University of Pennsylvania',
  'Keuka College',
  'K.S. School of Business Management',
  'Keck Graduate Institute of Applied Life Sciences',
  'Keiser University',
  "King's College London",
  'Kenrick-Glennon Seminary',
  'Kaunas University of Technology',
  'Kensington High School Complex',
  'Kentucky Mountain Bible College',
  'Kent State University–Columbiana County',
  'Kalamazoo College',
  'Kitchener-Waterloo Collegiate & Vocational School',
  'Kent State University–Trumbull Campus',
  'Kendall College',
  'Kent State University–Ashtabula Campus',
  'Kent State University–Stark',
  'Kettering University',
  'Kansas City University of Medicine & Biosciences',
  'Keele University',
  'Keene State College',
  'Kennesaw State University',
  'Kansas State University–Salina',
  'Kent State University–Geauga',
  'Kennett High School',
  'Kentucky Christian University',
  'Kuyper College',
  'Kantipur Engineering College',
  'Kansas Wesleyan University',
  'Kentucky Wesleyan College',
  'Karlsruhe Institute of Technology',
  'Kent State University',
  'King College',
  "King's College",
  'Kent State University–College of Podiatric Medicine',
  'Kean University',
  'Kwantlen University College',
  'Kaplan University',
  'Kettering College',
  'Kenyon College',
  'King Edward VI Five Ways School',
  'Kansas State University',
  'London School of Economics and Political Science',
  'LaGuardia Community College, CUNY',
  'Luther Seminary',
  'Louisiana Tech University',
  'Louisiana State University Health Sciences Center–New Orleans',
  'Long Island University–Brooklyn Campus',
  'Longwood University',
  'Laguna College of Art & Design',
  'Lodz University of Technology',
  'Lakehead University',
  'Louisiana State University–Alexandria',
  'Loch Raven High School',
  'Linfield College',
  'LeMoyne-Owen College',
  'Loras College',
  'Loyola University Maryland',
  'Lake Forest Graduate School of Management',
  'Luleå University of Technology, LTU',
  'Lamar University',
  'La Roche College',
  'Lake Erie College',
  'Life Pacific College',
  'Liberty University',
  'Lowell High School',
  'Lehigh University',
  'Lampeter-Strasburg High School',
  'Linn-Mar High School',
  'Lubbock Christian University',
  'Lee University',
  'Long Island University–Hudson',
  'Lynchburg College',
  'Lutheran Theological Seminary at Gettysburg',
  'Lourdes University',
  'Lincoln College',
  'Lankenau High School',
  'Lehman College',
  'Lake Superior State University',
  'Lewis-Clark State College',
  'La Salle University - Philadelphia',
  'Limestone College',
  'L D College Of Engineering Library',
  'Lincoln Technical Institute - Center City Philadelphia',
  'London Metropolitan University',
  'Long Island University–Riverhead',
  'Lake Forest College',
  'Lakeland College',
  'Lyndon State College',
  'Lincoln College of New England',
  'Louisville Presbyterian Theological Seminary',
  'Langara College',
  'Loyola Marymount University',
  'Life University',
  'Luther College',
  'Lock Haven University of Pennsylvania',
  'Lincoln Technical Institute - Northeast Philadelphia',
  'Lehman College, CUNY',
  'Langston University',
  'Loughborough University',
  'Lewis & Clark College',
  'Lancaster University',
  'Lenoir-Rhyne University',
  'Lycoming College',
  'LeTourneau University',
  'Le Moyne College',
  'Lord Krishna College of Engineering',
  'Long Island University–Brentwood Campus',
  'Lexington College',
  'Lakeside High School',
  'Lutheran Theological Seminary at Philadelphia',
  'Lipscomb University',
  'Lasell College',
  'Lafayette College',
  'Lancaster Theological Seminary',
  'Laurentian University of Sudbury',
  'Leiden University',
  'Lancaster Bible College',
  'Lander University',
  'La Salle University',
  'Lynn University',
  'Lebanon Valley College',
  'Lisgar Collegiate Institute',
  'Loyola University New Orleans',
  'Lane College',
  'Lutheran School of Theology at Chicago',
  'La Sierra University',
  'Los Altos High School',
  'Livingston High School',
  'Lake-Sumter State College',
  'Loyola University Chicago',
  'Lindenwood University',
  'Little Flowers Public Sr Secondary School',
  'Labouré College',
  'Lewis University',
  'Louisiana State University Health Sciences Center–Shreveport',
  'Lincoln Memorial University',
  'Loma Linda University',
  'Lone Star College System',
  'Lick Wilmerding High School',
  'Lexington High School',
  'Longy School of Music',
  'Louisiana State University–Shreveport',
  'Lake Erie College of Osteopathic Medicine',
  'Lakeview College of Nursing',
  'LIM College',
  'LaGrange College',
  'Lawrence University',
  'Louisiana State University System',
  'Lyme Academy College of Fine Arts',
  'Laval University',
  'Long Island University–C.W. Post Campus',
  'Lake Braddock Secondary School',
  'Louisiana College',
  'Lesley University',
  'Livingstone College',
  'Lees-McRae College',
  'Landmark College',
  'Lawrence Technological University',
  'Logan University',
  'Lynbrook High School',
  'Lincoln Christian University',
  'Louisiana State University',
  'Long Island University',
  'Lincoln University',
  'Louisiana State University–Law Center',
  'Long Island University–Arnold & Marie Schwartz College of Pharmacy & Health Sciences',
  'Lindsey Wilson College',
  'Lyon College',
  'Moscrop Secondary School',
  'Montgomery Blair High School',
  'Mount Holyoke College',
  "Maggie L. Walker Governor's School",
  'Marist College',
  'Mercyhurst College',
  'Mount Mary College',
  'Michigan Technological University',
  'Mercy College of Health Sciences',
  'Maumee Valley Country Day School',
  'Metropolia University of Applied Sciences',
  'Mississippi State University',
  'Maharishi University of Management',
  'Manipal Institute of Technology',
  'Meredith College',
  'Montserrat College of Art',
  'Maritime Academy Charter School (MACHS)',
  'Mount Angel Seminary',
  'Medgar Evers College, CUNY',
  'Moscow Institute of Physics and Technology',
  'Morton College',
  'Metropolitan Campus',
  'Montgomery High School',
  'Midland University',
  'Mansfield University of Pennsylvania',
  'Monmouth College',
  "Mount Saint Mary's University",
  'Middlesex County Academy for Science, Mathematics & Engineering Technologies',
  'MGH Institute of Health Professions',
  'Middletown High School South',
  'Muskingum University',
  'Murray State University',
  'Marylhurst University',
  'Manor College',
  'Moorestown High School',
  'Midwood',
  'Midwestern University',
  'Monroe Community College',
  'Missouri University of Science and Technology',
  'Mountain View High School',
  'Maryville College',
  'Montclair State University',
  'Morris College',
  'Middle Georgia State College',
  'Mount Aloysius College',
  'Miami Lakes Educational Center',
  'Massachusetts College of Liberal Arts ',
  'Methodist Theological School in Ohio',
  'Metuchen High School',
  'Montgomery County Community College - Central Campus (Blue Bell)',
  'Millburn Middle School',
  'Marshall High School',
  'Mount Saint Mary College',
  'Moore Middle School',
  'Manhattan High School',
  'Mastery Charter School at Shoemaker Campus',
  'Middle Tennessee State University',
  'Millsaps College',
  'Miami International University of Art & Design',
  'Manhattan College',
  'Mars Hill College',
  'Maritime College at Fort Schuyler',
  'Marygrove College',
  'Maui College',
  'Mississippi College',
  'Multi-Cultural Academy Charter School',
  'Marianopolis College',
  'Middlebury College',
  'Mount St. Vincent University',
  'Massachusetts School of Professional Psychology',
  'Minnesota State University–Moorhead',
  'Michigan State University',
  'Mitchell College',
  'Millville Senior High School',
  'Moody Bible Institute',
  'Menlo School',
  'Marshall University',
  'Mid-Atlantic Christian University',
  'Marian University',
  'Markville Secondary School',
  'Mount Allison University',
  'Manhattan School of Music',
  'Motilal Nehru National Institute of Technology',
  'Motilal Nehru National Institute of Technology Allahabad',
  'Millburn High School',
  'Midway College',
  'Maulana Azad National Institute of Technology',
  'Mayville State University',
  'Martin Luther King High School',
  'Missouri Valley College',
  'Massachusetts College of Art & Design',
  'Mid-Continent University',
  'Marine Corps University',
  'McNeese State University',
  'Maharaja Agrasen Institute of Technology',
  'Mississippi University for Women',
  'Middlesex University',
  'Metropolitan State University of Denver',
  'Marymount University',
  'Mayo Clinic College of Medicine–Medical School',
  'Manitoba',
  'Madison College',
  'Moravian College',
  'Mohammed V University',
  'Minot State University',
  'Macaulay Honors College, CUNY',
  'Maryland Institute College of Art',
  'M.V.Jayaraman College of Engineering',
  'Maulana Azad National Institute of Technology Bhopal',
  'Maryland University of Integrative Health',
  'Marymount Manhattan College',
  'Midwestern Baptist Theological Seminary',
  'Menlo College',
  'Millikin University',
  'Mission San Jose High School',
  'Marquette University',
  'Menno Simons College',
  'Methodist College',
  'Missouri Southern State University',
  'Martin Luther College',
  'Molloy College',
  'Mayo Clinic College of Medicine',
  'McPherson College',
  'Massachusetts Institute of Technology',
  'Montville Township High School',
  'Maine College of Art',
  'MacArthur High School',
  'Morehead State University',
  'Mountain State University',
  'Manchester University',
  'Monroe College',
  'Manalapan High School',
  'Mastery Charter School at Pickett Campus',
  'Muhlenberg College',
  'Miami University–Regional Campuses',
  'Memphis College of Art',
  'Madan Mohan Malaviya University of Technology',
  'Marcellus High School',
  'Merrimack College',
  'Millersville University of Pennsylvania',
  'Macomb Community College',
  'Multnomah University',
  'Midwestern State University',
  'McGill University',
  'McCormick Theological Seminary',
  'Masaryk University',
  'Manhattan Christian College',
  'Maulana Abul Kalam Azad University of Technology',
  'Montana State University',
  'Metropolitan State University',
  'Misericordia University',
  'Mount Ida College',
  'Miles College',
  'Muhlenberg college',
  'Montgomery College',
  'Metropolitan College of New York',
  'Mills College',
  'Marlboro High School',
  'Mount Vernon Nazarene University',
  'McKendree University',
  'Maine South High School',
  'Monta Vista High School',
  'Mercer University',
  'MidAmerica Nazarene University',
  'Manchester Metropolitan University',
  'Mayo Clinic College of Medicine–School of Health Sciences',
  'Milwaukee School of Engineering',
  'Minneapolis College of Art & Design',
  'Macalester College',
  'Mastery Charter School at Lenfest Campus',
  'Mississippi Valley State University',
  'Montgomery County Community College - West Campus (Pottstown)',
  'Minnesota State University–Mankato',
  'Mariana Bracetti Academy Charter School',
  'Middlesex County College',
  'Metas Adventist School',
  'Memphis Theological Seminary',
  'Marc Garneau Collegiate Institute',
  'Malone University',
  'Mercy College of Northwest Ohio',
  'Midstate College',
  'Miller College',
  'Medical University of Silesia',
  'McMurry University',
  'Milligan College',
  'Math, Civics and Sciences Charter School - Philadelphia',
  'Montreat College',
  'Martin Methodist College',
  'Mayo Clinic College of Medicine–Graduate School',
  'Methodist University',
  'Medaille College',
  'Morehouse College',
  'Martin University',
  'Maine Maritime Academy',
  'Marywood University',
  'Malvern Preparatory School',
  'Medical College of Wisconsin',
  'Moore College of Art and Design',
  'Maranatha Baptist University',
  'MacMurray College',
  'Milwaukee Institute of Art & Design',
  'Maria College of Albany',
  'Morris County School of Technology',
  'Murrell Dobbins Technical High School',
  'Mount Mercy University',
  'Mastery Charter School - Hardy Williams Academy',
  'Madonna University',
  'Moore College of Art & Design',
  'Mount Carmel College of Nursing',
  'Montana State University–Northern',
  'McDaniel College',
  'Massachusetts College of Pharmacy & Health Sciences',
  'Mission College Boulevard',
  'Meharry Medical College',
  'Messiah College',
  'Medgar Evers College',
  'Mid-America Christian University',
  'Mount Marty College',
  'Maharaja Surajmal Institute of Technology',
  'Missouri University of Science & Technology',
  'Mt. San Antonio College',
  'Middle Tennessee School of Anesthesia',
  'Mastery Charter School - Thomas Campus',
  'Marietta College',
  'Morehouse School of Medicine',
  'Monmouth University',
  'McMaster University',
  'MBM Engineering College, Jodhpur',
  'Mercy College',
  'Missouri State University',
  'Mathematics, Science, and Technology Community Charter School (MaST)',
  'Mount Washington College',
  'Maryville University of Saint Louis',
  'Marlboro College',
  'Mary Baldwin College',
  'Montana Tech of The University of Montana',
  'Mount Royal College',
  'Massachusetts Maritime Academy',
  'Morgan State University',
  'Morningside College',
  'Mercer County Community College',
  'Malaspina University College',
  'Mid-America Baptist Theological Seminary',
  'Michigan School of Professional Psychology',
  'Miami University',
  "Mount Saint Mary's College",
  'Missouri Western State University',
  'Memorial University of Newfoundland',
  'Missouri Baptist University',
  'Massachusetts School of Law',
  'Moraine Valley Community College',
  'Microsoft School of the Future High School',
  'Marymount California University',
  'Motivation High School (formerly John Bartram High School)',
  'Medical University of South Carolina',
  'Manhattanville College',
  'Markham District High School',
  'Mater Academy High School',
  'Miami Dade College',
  'Montana State University–Billings',
  'New Jersey City University',
  'Nipissing University',
  'North Carolina A&T State University',
  'Northwestern Oklahoma State University',
  'Notre Dame de Namur University',
  'North Carolina School of Science and Mathematics',
  'Northern Secondary School',
  'Naval Postgraduate School',
  'Norco College',
  'Nirma University',
  'Northwestern College',
  'New York University',
  'North Park University',
  'New England College of Optometry',
  'New Orleans Baptist Theological Seminary',
  'Northern Virginia Community College',
  'New England Institute of Art',
  'New Mexico State University',
  'Northeastern Illinois University',
  'Nashua High School South',
  'Northwest Christian University',
  'New York University Abu Dhabi',
  'North Hunterdon High School',
  'Nazareth College',
  'Newberry College',
  'New River Community College',
  'National Institute of Technology, Jamshedpur',
  'Northwest Parkway High School',
  'New England College of Business & Finance',
  'Northeastern State University',
  'Northern Baptist Theological Seminary',
  'Northeastern State University–Muskogee',
  'National Institute of Technology, Warangal',
  'New Albany High School',
  'Norwich University',
  'North American University',
  'Northwestern University',
  'Northeastern Seminary at Roberts Wesleyan College',
  'National Institute of Technology, Raipur',
  'National University of Health Sciences',
  'Northern State University',
  'Noakhali Science and Technology University',
  'Northwood University',
  'North Park Secondary School',
  'Northwest University',
  'North Central University',
  'Notre Dame College',
  'Northwest Florida State College',
  'Notre Dame Seminary',
  'North Penn High School',
  'Northwestern Health Sciences University',
  'Newfoundland',
  'Nova Southeastern University',
  'Netaji Subhas Institute Of Technology',
  'National Research University Higher School Of Economics',
  'Nova Scotia College of Art and Design',
  'Nanyang Technological University',
  'Northern Illinois University',
  'New Jersey Institute of Technology',
  'Northwest Vista College',
  'Northview High School',
  'Nova Scotia',
  'NewSchool of Architecture & Design',
  'Northwest Indian College',
  'Norfolk State University',
  'Navajo Technical University',
  'Nicholls State University',
  'Nyack College',
  'Niagara College',
  'North Dakota State University',
  'New Mexico Institute of Mining & Technology',
  'Northern Kentucky University',
  'Nebraska Wesleyan University',
  'Northeast High School - Philadelphia',
  'Neumann University',
  'Narsee Monjee College of Commerce and Economics',
  'Newbury College',
  'New England Conservatory of Music',
  'Newton South High School',
  'New College of Florida',
  'Newman University',
  'National College of Natural Medicine',
  'New York Institute of Technology',
  'Northwestern State University',
  'New Hope Christian College',
  'Northern Arizona University',
  'Nebraska Christian College',
  'Nova Scotia Agricultural College',
  'National Labor College',
  'North Greenville University',
  'National Institute of Technology, Silchar',
  'Northeast Ohio Medical University',
  'North Carolina Central University',
  'North Brunswick Township High School',
  'Nichols College',
  'North Carolina Agricultural and Technical (A&T) State University',
  'National Institute of Technology, Srinagar',
  'National Institute of Engineering, Mysore',
  'North Island College',
  'North Carolina Wesleyan College',
  'New York Medical College',
  'North Central College',
  'National Institute of Technology, Tiruchirappalli',
  'National Graduate School of Quality Management',
  'New Mexico Highlands University',
  'National American University',
  'Novi High School',
  'North Andover High School',
  'Northwest Missouri State University',
  'Northern Lights College',
  'Northwood Academy/Arts School',
  'Netaji Subhash Engineering College',
  'Northern Alberta Institute of Technology',
  'New York Chiropractic College',
  'National University',
  'North Carolina State University',
  'New England Institute of Technology',
  'National Defense University',
  'National Hispanic University',
  'Northland College',
  'Northeastern University',
  'Northwest Nazarene University',
  'National-Louis University',
  'New York City College of Technology, CUNY',
  'National Intelligence University',
  'Northcentral University',
  'Nottingham Trent University',
  'New Foundations Charter School - Philadelphia',
  'North Shore Community College',
  'Northern New Mexico College',
  'Nazarene Bible College',
  'Niagara University',
  'Northeastern State University–Broken Arrow',
  'Naropa University',
  'New England College',
  'New York City College of Technology',
  'New Providence High School',
  'Newcastle University',
  'Nebraska Methodist College',
  'Northern Michigan University',
  'New Brunswick',
  'National Institute of Technology Calicut',
  'Oblate School of Theology',
  'Olivet Nazarene University',
  'Orleans Technical Institute',
  'Oglethorpe University',
  'Ohio University–Zanesville',
  'Oak Hills Christian College',
  'Oregon Health & Science University',
  'Ohio Northern University',
  'Our Lady of the Lake University',
  'Ostbayerische Technische Hochschule Regensburg',
  'Oberlin College',
  'Oregon College of Art & Craft',
  'Oakwood University',
  'Oglala Lakota College',
  'Oklahoma State University–Tulsa',
  'Oregon Institute of Technology',
  'Oral Roberts University',
  'Ohio State University–Lima',
  'Ouachita Baptist University',
  'Our Lady of the Lake College',
  'Old Dominion University',
  'Oklahoma Baptist University',
  'Ozark Christian College',
  'Osbourn Park High School',
  'Oklahoma State University–Oklahoma City',
  'Oregon State University',
  'Oakland Community College',
  'Oklahoma Panhandle State University',
  'Oxford Academy High School',
  'Ohio Dominican University',
  'Okemos High School',
  'Oakland City University',
  'Oxford Brookes University',
  'Ohio Wesleyan University',
  'Ottawa University',
  'Okanagan University College',
  'Olympic College',
  'Ohio University–Eastern Campus',
  'Oklahoma Wesleyan University',
  'Ohio State University–Marion',
  'Ohio Christian University',
  'Oklahoma State University–Institute of Technology',
  'Ontario Institute for Studies in Education',
  'Open Learning Agency',
  'Overbrook High School - Philadelphia',
  'Ohio State University',
  'Oakland University',
  'Oklahoma Christian University',
  'Olivet University',
  'Oklahoma State University',
  'Otterbein University',
  'Onondaga Community College',
  'Ontario College of Art and Design',
  'Ontario',
  'Otis College of Art & Design',
  'Olney High School',
  'Our Lady of Holy Cross College',
  'Opolska University of Technology',
  'Occidental College',
  'Ohio University',
  'Ocean City High School',
  'Olivet College',
  'Oklahoma City University',
  'Ohio State University–Newark',
  'Ocean County College',
  'Ohio State University–Mansfield',
  'Oklahoma State University–Center for Health Sciences',
  'Ohio Valley University',
  'Ohio University–Lancaster',
  'Pennsylvania State University–Mont Alto',
  'Pascal English School, Cyprus',
  'Plymouth State University',
  'Pennsylvania State University–Hazleton',
  'Palmer College of Chiropractic',
  'Purdue University College of Technology–Kokomo',
  'Pittsburgh Technical College - Philadelphia',
  'Pennsylvania State University–Abington',
  'Pacific University',
  'Philadelphia Performing Arts Charter School (String Theory High School) - Vine Street Campus',
  'Presentation College',
  'Pacific Lutheran University',
  'Paul Quinn College',
  'Phillips Graduate Institute',
  'Pennsylvania State University–Shenango',
  'Presbyterian College',
  'Pepperdine University',
  'Paul Robeson High School (formerly John Bartram High School)',
  'Pennsylvania State University–DuBois',
  'Pensacola State College',
  'Pardee RAND Graduate School',
  'Pennsylvania State University–Erie, The Behrend College',
  'Purdue University College of Technology–New Albany',
  'Point University',
  'Penncrest High School',
  'Point Loma Nazarene University',
  'Pacific Union College',
  'Pomona College',
  'Purdue University College of Technology–Lafayette',
  'Philadelphia Academy Charter School',
  'Pine Manor College',
  'Pierre Elliott Trudeau High School',
  'Plano East Senior High School',
  'Preston University',
  'Pennsylvania State University–Brandywine',
  'Piedmont High School',
  'Pennsylvania State University–Harrisburg',
  'Paramount International School',
  'Pennsylvania State University–Schuylkill',
  'Pasadena City College',
  'Pacific School of Religion',
  'Princeton High School',
  'Pennsylvania Academy of the Fine Arts',
  'Parker University',
  'Pennsylvania State University–York',
  'Palomar College',
  'Porter-Gaud School',
  'Pennsylvania State University–Great Valley School of Graduate Professional Studies',
  'Philadelphia College of Osteopathic Medicine',
  'Ponce School of Medicine & Health Sciences',
  'Poolesville High School',
  'Polk State College',
  'Pillar College',
  'Principia College',
  'Pokhara University',
  'Penn State Erie, The Behrend College',
  'Pittsburgh Theological Seminary',
  'Pennsylvania College of Health Sciences',
  'Piedmont College',
  'Philadelphia Electrical and Technology Charter School',
  'Pontificia Universidad Católica de Puerto Rico',
  'Plovdiv Medical University',
  'Pennsylvania State University–Altoona',
  'Potomac Senior High School',
  'Purdue University College of Technology–Anderson',
  'Poornima College of Engineering',
  'Pacific Northwest College of Art',
  'Purdue University College of Technology–Richmond',
  'Pennsylvania Institute of Technology - Center City Philadelphia',
  'Palo Alto High School',
  'Piscataway Township High School',
  'Pennsylvania State University–Fayette, The Eberly Campus',
  'Providence College',
  'Parul Institute of Engineering & Technology',
  'Ponce Campus',
  'Pennsylvania State University–Lehigh Valley',
  'Philadelphia College of Textiles & Science',
  'Pacifica Graduate Institute',
  'Prince Edward Island',
  'Pandit Deendayal Petroleum University',
  'Point Park University',
  'Polsko-Japońska Akademia Technik Komputerowych',
  'Pennsylvania State University–Berks',
  'Pima Community College',
  'Phillips Theological Seminary',
  'Pennsylvania State University–Worthington Scranton',
  'Parkview High School',
  'Prescott College',
  'Parkway Center City High School',
  'Phillips University',
  'Patten University',
  'Park University',
  'Patriot High School - Nokesville',
  'Purdue University',
  'Port Credit Secondary School',
  'Peirce College',
  'Pfeiffer University',
  'Pennsylvania Institute of Technology - Media',
  'Parsippany High School',
  'Pennsylvania Leadership Charter School - Online',
  'Philander Smith College',
  'Pennsylvania Virtual Charter School',
  'Potsdam, SUNY',
  'Politecnico di Milano',
  'Pacific Oaks College',
  'Poznań University of Technology',
  'Presidency School, Surat.',
  'Paine College',
  'Pennsylvania State University–Greater Allegheny',
  'Pennsylvania Distance Learning Charter School - Online',
  'Pennsylvania College of Art & Design',
  'Purdue University–Calumet',
  'Prairie View A&M University',
  'Pennsylvania State University–Beaver',
  'Princeton University',
  'Pennsylvania State University–Dickinson School of Law',
  'Pingree School',
  'Pennsylvania Cyber Charter School',
  'Pace University',
  'Pennsylvania College of Technology',
  'Peninsula College',
  'Pennsylvania State University–New Kensington',
  "Paul Smith's College",
  'Purdue University College of Technology–South Bend',
  'Peace College',
  'Pratt Institute',
  'Patriot High School - Riverside',
  'Pontifical College Josephinum',
  'Philadelphia High School for Girls',
  'Pittsburgh Technical Institute',
  'Pitzer College',
  'PES University',
  'Purdue University College of Technology–Columbus',
  'Palm Beach State College',
  'Preston High School',
  'Parkway West High School',
  'Pennsylvania State University–Wilkes-Barre',
  'Purdue University–North Central',
  'Purdue University College of Technology–Indianapolis',
  'Post University',
  'Pennsylvania State University',
  'Palm Beach Atlantic University',
  'Parsons School of Design',
  'Peru State College',
  'Pennsylvania State University–Milton S. Hershey Medical Center',
  'Princeton Theological Seminary',
  'Pentecostal Theological Seminary',
  'Palo Alto University',
  'Portland State University',
  'Pope John Paul II High School',
  'Pittsburg State University',
  "Queen's University",
  'Queens College',
  'Quebec',
  'Quincy University',
  'Queen Mary University of London',
  "Queen's University",
  'Queens College, CUNY',
  'Queens University of Charlotte',
  'Queensborough Community College, CUNY',
  'Quinnipiac University',
  'Rockhurst University',
  'Rose-Hulman Institute of Technology',
  'Ryerson University',
  'Reinhardt University',
  'Richmont Graduate University',
  'Rowan University',
  'Rochester Institute of Technology',
  'Roseman University of Health Sciences',
  'River Dell High School',
  'Roxbury High School',
  'Roberts Wesleyan College',
  'Ripon College',
  'Rice University',
  'Roger Williams University',
  'Renison College',
  'Reconstructionist Rabbinical College',
  'Rowan College at Burlington County - Mount Holly',
  'Red Bank Regional High School',
  'Rush University',
  'Rust College',
  'Richland College',
  'Regis College',
  'Robert Morris University',
  'Richard Stockton University',
  'Regis High School',
  'Rogers State University',
  'Rudbecksgymnasiet',
  'Rutgers University–New Brunswick',
  'Raksha Shakti University',
  'Rhodes College',
  'Ryde School',
  'Rutgers University - Newark',
  'Rocky Mountain College',
  'Rutgers University–Newark',
  'Resurrection University',
  'Rutgers University–Camden',
  'Ramapo High School',
  'Rhode Island School of Design',
  'Rasmussen College',
  'Rollins College',
  'Redeemer College',
  'Ravenscroft School',
  'Reach Cyber Charter School',
  'Ringling College of Art & Design',
  'Rocky Mountain College of Art & Design',
  'R.V. College Of Engineering',
  'Ranken Technical College',
  'Riga Technical University',
  'Research College of Nursing',
  'Roosevelt University',
  'Roanoke College',
  'Rowan College at Gloucester County - Mount Laurel',
  'Rheinisch-Westfälische Technische Hochschule Aachen (RWTH)',
  'Richard Montgomery High School',
  'Regis University',
  'Randolph-Macon College',
  'Reed College',
  'Richard Stockton College of New Jersey',
  'Richardson High School',
  'Ryerson Polytechnic University',
  'Roosevelt High School',
  'Reformed Theological Seminary',
  'Radford University',
  'Ramapo College of New Jersey',
  'Rowan College at Burlington County - Pemberton',
  'Rutgers, The State University of New Jersey',
  'Rani Laxmi Bai Public School',
  'Royal Military College of Canada',
  'Radnor High School',
  'Robert Gordon University',
  'Royal Roads University',
  'Rutgers University – Camden',
  'Rosalind Franklin University of Medicine & Science',
  'Ravenwood High School',
  'Rivier University',
  'Regent University',
  'Richmond Hill High School',
  'Rockford University',
  'Ridgewood High School',
  'Raritan Valley Community College',
  'Randolph College',
  'Rensselaer Polytechnic Institute',
  'Rowan College at Burlington County - Willingboro',
  'Rider University',
  'Roxborough High School',
  'Rosemont College',
  'Robert Morris University–Illinois',
  'Rosa Parks Middle School',
  'Rock Ridge High School',
  'Royal Holloway, University of London',
  'Rochester College',
  'Rhode Island College',
  'Saint Johns River State College',
  'South College',
  'Sam Houston State University',
  'Saint Anthony College of Nursing',
  'Saint Joseph Seminary College',
  'Syracuse University',
  'School of Professional Studies, CUNY',
  'Southern California University of Health Sciences',
  "Saint Joseph's Seminary",
  'Saratoga High School',
  'Selkirk College',
  'St. Francis Xavier University',
  'Strayer University',
  'Saybrook University',
  'St. David Catholic Secondary School',
  'Samford University',
  'South Texas College',
  "Saint Luke's College",
  'Spelman College',
  'Siena Heights University',
  'San Germán Campus',
  'Sarvajanik College of Engineering & Technology',
  'Southern Illinois University Edwardsville',
  'Salve Regina University',
  "St Paul's Catholic College – Sunbury-on-Thames",
  'Southwest Minnesota State University',
  'Silver Oak College of Engineering & Technology',
  "Saint Vincent's College",
  'San José State University',
  "St. Xavier's Senior Secondary School, Jaipur",
  "Saint Paul's College",
  'Southeast Missouri State University',
  'Saint Anselm College',
  "Saint John's College–Santa Fe",
  'Saint Bonaventure University',
  'Santa Clara University',
  'Sacred Heart School of Theology',
  'SOAS University of London',
  'Saint Joseph High School',
  'Santa Margarita Catholic High School',
  'Sitarambhai Naranji Patel Institute of Technology & Research Centre',
  'Simón Bolívar University',
  'St. Thomas More College',
  'St. Raymond High School for Boys And Girls',
  'San Francisco Theological Seminary',
  'Salem International University',
  'Stillman College',
  'Southern University at New Orleans',
  "St. John's University, New York",
  'San Jose State University',
  'Santa Fe University of Art & Design',
  'Salem State University',
  'Sir John A. Macdonald Secondary School',
  'Sanford College of Nursing',
  'School of Visual Arts, New York',
  'South Lakes High School',
  'Shepherd University',
  'Susq-Cyber Charter School',
  'Saint Andrews Presbyterian College',
  'Southern Oregon University',
  'Sitting Bull College',
  "Saint Joseph's College of Maine",
  'Saint Louis University',
  'Santa Rosa Junior College',
  'Stony Brook University',
  'Seminole State College of Florida',
  'San Marcos High School',
  'Stephen F. Austin State University',
  'Sreenidhi Institute of Science & Technology',
  'Saint Louis College of Pharmacy',
  'San Francisco Conservatory of Music',
  'Southwestern College',
  'Southwestern Baptist Theological Seminary',
  'Southeastern Oklahoma State University',
  'Springfield College',
  'Sinte Gleska University',
  'San Joaquin College of Law',
  'Science Leadership Academy',
  'Scranton High School',
  "Saint Mary's College of Maryland",
  'Southern Baptist Theological Seminary',
  'Sacred Heart Major Seminary',
  "St. Paul's College",
  'South Dakota State University',
  'Shenandoah University',
  'South Hills School of Business & Technology',
  'Shri Vaishnav Institute of Technology and Science',
  'Sierra Nevada College',
  'Stockholm University',
  'St. Charles Borromeo Seminary',
  'Staten Island Technical High School',
  'Southern College of Optometry',
  'Sardar Vallabhbhai National Institute of Technology, Surat',
  'Saint Francis College',
  'School for International Training',
  'Southern Vermont College',
  'Saint Mary-of-the-Woods College',
  'Saurashtra University Rajkot',
  "Saint Joseph's College",
  'Southern Illinois University Carbondale',
  "Saint Michael's College",
  'Saint Vincent de Paul Regional Seminary',
  'Sofia University',
  'Stonehill College',
  'San Francisco State University',
  'Sioux Falls Seminary',
  'Southwestern Assemblies of God University',
  'Shimer College',
  'South Philadelphia High School',
  'Saint Vincent College',
  'Seven Lakes High School',
  'Savitribai Phule Pune University',
  'Samuel Fels High School - Philadelphia',
  "Saint Mary's University of Minnesota",
  'Shiv Nadar University',
  'South University',
  'Sherman College of Chiropractic',
  'Sarah Lawrence College',
  'SUNY Polytechnic Institute',
  'Seminary of the Southwest',
  'Southern Adventist University',
  'Shaw University',
  'Shippagan campus',
  'Spalding University',
  'Saint Petersburg College',
  'Saginaw Valley State University',
  'Stockton University',
  "St Mary's Catholic High School – Croydon",
  'Southeast',
  'Stuyvesant High School',
  "Saint Joseph's Preparatory School - Philadelphia",
  'School of Optometry',
  "Saint Peter's Preparatory School",
  "St. Jerome's College",
  'Seminary of the Immaculate Conception',
  'Stevenson University',
  'Southern California Institute of Architecture',
  'Swarthmore College',
  'Slovak University of Technology in Bratislava (STU)',
  'Sussex County Community College',
  'Saskatchewan Indian Federated College',
  'Southern University System',
  'Southern Connecticut State University',
  'Spring Arbor University',
  'Strayer University - Bensalem',
  'South Florida State College',
  "St. Mary's Convent School",
  'Santa Fe College',
  "St. Michael's College",
  'Shippensburg University of Pennsylvania',
  'Saint Olaf College',
  'Salem College',
  'Shaker High School',
  "St. Mary's University",
  'Southern Arkansas University',
  'Southwest College of Naturopathic Medicine & Health Sciences',
  'Seneca College',
  'Saint Francis Medical Center College of Nursing',
  "Saint Mary's Seminary & University",
  'Stanford University',
  'Saint Meinrad Seminary & School of Theology',
  'Santa Barbara City College',
  'School of the Art Institute of Chicago',
  'Southern University and A&M College at Baton Rouge',
  'Saint Augustine College',
  'Swansea University',
  'St Edwards University',
  'Sacred Heart University',
  'Saint Norbert College',
  "Saint Patrick's Seminary & University",
  'Southeastern Baptist Theological Seminary',
  'Sahrdaya College of Engineering and Technology',
  "Saint Mary's University",
  'Silesian University of Technology',
  'Shri Govindram Seksaria Institute of Technology and Science',
  'Saint John Fisher College',
  "Saint Mary's College",
  'Southern University and A&M College',
  'Saskatchewan',
  'Seattle Pacific University',
  'Scripps Research Institute',
  'Southern Wesleyan University',
  'Simpson University',
  'South Florida Bible College & Theological Seminary',
  'St. Michael College of Engineering & Technology',
  'Spring Hill College',
  'Sullivan University',
  'Shankersinh Vaghela Bapu Institute of Technology',
  'Seattle University',
  'State College of Florida, Manatee-Sarasota',
  'Saint Lawrence University',
  'Salus University',
  'Simon Fraser University',
  'South Carolina State University',
  "Saint John's College–Annapolis",
  'Springside Chestnut Hill Academy',
  'Shawnee State University',
  'Shelton High School',
  "St. Paul's United College",
  'Saint Cloud State University',
  'Southern Nazarene University',
  'Soujourner-Douglass College',
  'San Marin High School',
  'Sardar Patel University',
  'Southern Utah University',
  'Saint Gregory the Great Seminary',
  'Saint Xavier University',
  'South Texas College of Law',
  'Savannah State University',
  'Southwestern Christian University',
  'Sonoma State University',
  'Southwestern Christian College',
  'Sulphur High School',
  'San Diego State University',
  'Shri Guru Ram Rai Public School',
  'San Mateo High School',
  'Sweet Briar College',
  'Sherwood Convent School',
  'Scripps College',
  'Salisbury University',
  'Southwestern Adventist University',
  'Southwestern University',
  "Saint John's Seminary",
  'Southern California College of Optometry',
  'Southern Virginia University',
  "St. Mark's School, Hong Kong",
  'Salem Community College',
  'Saint Charles Borromeo Seminary',
  'South Brunswick High School',
  'Star Technical Institute',
  'Schreiner University',
  "Saint John's University",
  'Stephens College',
  'Sir Padampat Singhania University',
  "St Mary's CE High School – Cheshunt",
  "Saint John's College",
  'Slippery Rock University of Pennsylvania',
  'St Brendan High School',
  "Saint Luke's College of Health Sciences",
  'Strayer University - Philadelphia Center City',
  'Saint Paul College',
  'Saint Mary Seminary & Graduate School of Theology',
  'South Dakota School of Mines and Technology',
  'Symbiosis International University',
  "Saint Mary's College of California",
  'Saint Thomas University',
  'San Juan Bautista School of Medicine',
  'Sankofa Freedom Academy Charter School',
  'Saint Catharine College',
  'Savannah College of Art & Design',
  'Sul Ross State University',
  'San Francisco Art Institute',
  'Simon Gratz High School',
  'Stonewall Jackson High School - Quicksburg',
  'Seventh Day Adventist High School',
  'Sistema Universitario Ana G. Méndez',
  'St. Theresa of Lisieux Catholic High School',
  'Southwest University of Visual Arts',
  'Sir Wilfred Grenfell College',
  'Sterling College',
  'Saint Paul University',
  'Seton Hall University',
  'Stony Brook University, SUNY',
  'South Dakota School of Mines & Technology',
  "Saint Joseph's College of Maine",
  'Samuel Merritt University',
  'Sheffield Hallam University',
  "Saint Joseph's University",
  'Saint Catherine University',
  'Susquehanna University',
  'School of Visual Arts',
  'Salish Kootenai College',
  'Saint Ambrose University',
  'Soka University of America',
  "Saint Augustine's University",
  'Suffolk University',
  'Spertus Institute of Jewish Studies',
  'Sant Longowal Institute of Engineering and Technology',
  'Saint Leo University',
  'School of Law',
  "Saint Peter's University",
  'Spotswood High School',
  "Saint Joseph's University - Philadelphia",
  'Saint Thomas Aquinas College',
  'Saint John Vianney College Seminary',
  'Silver Lake College',
  'Saint Paul School of Theology',
  'Siena College',
  'St. Cloud State University',
  'Southwest Baptist University',
  'State University of New York System',
  'Strawberry Mansion High School',
  'Southern Polytechnic State University',
  'Souderton Area High School',
  "Saint Martin's University",
  'Seton Hill University',
  'Sherwood High School',
  'Stevens Institute of Technology',
  "St. Mary's Ryken High School",
  'Stetson University',
  'Southern New Hampshire University',
  'San Diego Christian College',
  'Smith College',
  'Shorter University',
  'Southeastern Bible College',
  'Sri Sivasubramaniya Nadar College of Engineering',
  'Southwestern Oklahoma State University',
  'Southern Illinois University–Edwardsville',
  'Southeastern University',
  'Southern Methodist University',
  'Simpson College',
  'Saint Francis University',
  'South Georgia State College',
  "Saint Peter's University",
  'Southern University at Shreveport',
  'Skidmore College',
  'Stonewall Jackson High School - Manassas',
  "Saint Gregory's University",
  "St. John's College",
  'Simmons College',
  'Southern Illinois University–Carbondale',
  "Saint Edward's University",
  'Singapore University of Technology and Design',
  'Seminario Evangélico de Puerto Rico',
  'Southern Alberta Institute of Technology',
  'Southeastern Louisiana University',
  'SUPINFO International University',
  'Texas A&M University Health Science Center',
  'Tadeusz Kościuszko University of Technology',
  'The University of New Brunswick',
  'Technical University of British Columbia',
  'Touro College',
  'The University of Tampa',
  'The University of Richmond',
  'The Pennsylvania State University',
  'The University of California, Davis',
  'The University of Alberta',
  'The University of Oklahoma',
  'Thunderbird School of Global Management',
  'The University of Houston – Downtown',
  'The University of St Andrews',
  'The University of Miami',
  'Truett-McConnell College',
  'Tulane University',
  'The University of Liverpool',
  'Trinity Lutheran Seminary',
  'The University of Portland',
  'The University of North Carolina at Charlotte',
  'The University of Málaga',
  'The University of Oxford',
  'The University of Arizona',
  'Tiffin University',
  'The Mount Tabor Training College',
  'The University of Washington Bothell',
  'The University of Dallas',
  'The University of Louisiana at Monroe',
  'Taylor University',
  'The University of Kansas',
  'The University of Wisconsin-Oshkosh',
  'Tallinn University of Technology',
  'The University of Portsmouth',
  'The University of Calicut',
  'The Bronx High School of Science',
  'The University of Chicago',
  'The University of California, Merced',
  'The University of Texas at San Antonio',
  "The Governor's School @ Innovation Park",
  'Tecnológico de Estudio Superiores de Ixtapaluca',
  'The Juilliard School',
  'The George Washington University',
  'The University of Guelph',
  'The University of Virginia',
  'Texas A&M University–San Antonio',
  'The University of Warsaw',
  'The Workshop School - Philadelphia',
  'Talmudical Yeshiva of Philadelphia',
  'The University of Stirling',
  'The University of Applied Sciences Upper Austria',
  'The University of Alabama',
  'The University of Wisconsin-Milwaukee',
  'The University of Central Florida',
  'The University of Wisconsin-La Crosse',
  'The University of Wisconsin-Parkside',
  'Texas A&M University – Central Texas',
  'The University of Manitoba',
  'Trinity Western University',
  'Texas A&M University–Galveston',
  'The University of Louisiana at Lafayette',
  'Tampere University of Technology',
  'The University of Dundee',
  'Tenafly High School',
  'Techno India University',
  'Tabor College',
  'The University of Utah',
  'Thomas Nelson Community College',
  'The University of Western Ontario',
  'Thiel College',
  'The University of Findlay',
  'The University of Westminster',
  'Towson High School',
  'The University of Rochester',
  'Trident University International',
  'The University of Iowa',
  'The University of the South - Sewanee',
  'The University of Texas at Dallas',
  'Trinity International University',
  'Texas State University',
  'The Jewish Theological Seminary of America',
  'Tougaloo College',
  'The University of Massachusetts Boston',
  'The University of the Pacific',
  'The University of Waterloo',
  'The University of Alabama at Birmingham',
  'The University of Wisconsin-Stevens Point',
  'Touro University Nevada',
  'Texas Wesleyan University',
  'The Wright Institute',
  'The University of Wrocław',
  'The University of West Georgia',
  'The University of Wisconsin-Green Bay',
  'The Catholic University of America',
  'The University of Wisconsin-Madison',
  'The University of St. Thomas',
  'The University of Ljubljana',
  'The Chicago School of Professional Psychology',
  'The University of New Hampshire',
  'The British University In Egypt',
  'Tusculum College',
  'Troy High School',
  'The University of Falmouth',
  'Thomas College',
  'Touro University California',
  'The University of Ottawa',
  'The University of Birmingham',
  'The University of Salford',
  'The University of Bonn',
  'Truman State University',
  'Trinity College',
  'The University of Silesia in Katowice',
  'The University of Wisconsin-Whitewater',
  'The University of Houston',
  'The University of Victoria',
  'The University of Leeds',
  'Tufts University',
  'Texas A&M University–Central Texas',
  'Thompson Institute - Philadelphia',
  'Techno India College of Technology',
  'The Open University',
  'The University of Illinois at Urbana-Champaign',
  'Trinity Valley School',
  'The Hill School',
  'The University of Denver',
  'The University of Edinburgh',
  'The University of California, San Diego',
  'Texas Tech University Health Sciences Center',
  'The University of Mississippi',
  'Texas College',
  'Texas Christian University',
  'Texas A&M University – Kingsville',
  'The University of New Haven',
  'Thomas Edison State College',
  'Tongji University',
  'Touro College Los Angeles',
  'The University of Louisville',
  'Trinity College of Florida',
  'Trinity Bible College',
  'The University of Bolton',
  'The University of Northampton',
  'The Pennsylvania State University – Harrisburg',
  'Talladega College',
  'Thomas S. Wootton High School',
  'The University of Toronto Scarborough',
  'The University of Stuttgart',
  'Thomas University',
  'TCM International Institute',
  'The University of Missouri-Kansas City',
  'The University of Bedfordshire',
  'The Pennsylvania State University – Brandywine',
  'The University of Glasgow',
  'The Sage Colleges',
  'Thomas Jefferson High School for Science and Technology',
  'The University of Information Technology and Management in Rzeszow',
  'The University of Oulu',
  'Technische Universitaet München',
  'Texas A&M University–Corpus Christi',
  'The University of Michigan-Flint',
  'Thomas A. Edison High School - Philadelphia',
  'The University of Colorado Boulder',
  'Tampere University of Applied Sciences',
  'The Pennsylvania State University – Berks',
  'The University of Cambridge',
  'The College Of William & Mary',
  'The Harker School',
  'The University of Colorado Colorado Springs',
  'The University of Petroleum and Energy Studies',
  'The University of North Texas',
  'Tallinn University',
  'The Baptist College of Florida',
  'The University of Bristol',
  'The New School',
  'The University of Leicester',
  'The University of British Columbia',
  'The University of Missouri',
  'The University of Texas of the Permian Basin',
  'Trent University',
  'The University of Windsor',
  'The University of Wisconsin-Stout',
  'The University of Manchester',
  'The University of Wisconsin-Eau Claire',
  'The University of Texas at Tyler',
  'Texas Chiropractic College',
  'The Technical University of Denmark',
  'The University of Tennessee',
  'The University of La Verne',
  'The University of Texas – Pan American',
  'The University of Notre Dame',
  'The University of Lincoln',
  'The University of Bath',
  'Texas A&M University',
  'Tecnológico de Estudios Superiores de Jilotepec',
  'The Citadel',
  'Tennessee State University',
  'The University of Nebraska-Lincoln',
  'The Roxbury Latin School',
  'The University of California, Berkeley',
  'The University of Wisconsin-Superior',
  'The University of Groningen',
  'Texas A&M University–Kingsville',
  'The Technische Universität Berlin',
  'The University of Texas at Arlington',
  'The Pennsylvania State University – York Campus',
  'Texas A&M University–Texarkana',
  'The University of South Carolina',
  "The King's University College",
  'The University of Exeter',
  'The University of Florida',
  'The University of Evansville',
  'Temple University - Rome',
  'The Katholieke Universiteit Leuven',
  'The University of North Carolina at Chapel Hill',
  'Temple University - Tokyo',
  'TU/e Technische Universiteit Eindhoven University of Technology',
  'The University of Southern California',
  'Tacoma Community College',
  'The University of Idaho',
  'Texas A&M University – Corpus Christi',
  'The LNM Institute of Information Technology',
  'The Maharaja Sayajirao University of Baroda',
  'The University of Michigan-Dearborn',
  'The University of Cincinnati',
  'The University of California, Santa Barbara',
  'Texas Southmost College',
  'The University of Puerto Rico, Río Piedras Campus',
  'Troy Athens High School',
  'Transylvania University',
  'The University of California, Irvine',
  'The Curtis Institute of Music',
  "The King's College",
  'The University of Texas at Austin',
  'The City College of New York, CUNY',
  'The University of Connecticut',
  'Trine University',
  'Touro University Worldwide',
  'Tecnológico de Estudios Superiores de Ecatepec',
  'The University of Wisconsin-River Falls',
  'Tshwane University of Technology',
  "Texas Woman's University",
  'The University of Toronto Mississauga',
  'Temple University',
  'The University of Kentucky',
  'The Ohio State University',
  'The University of York',
  'Trinity College of Nursing & Health Sciences',
  'Texas A&M International University',
  'TECH Freire Charter High School',
  'The University of Essex',
  'The University of Michigan',
  'Turtle Mountain Community College',
  "The Master's College",
  'Trevecca Nazarene University',
  'The University of Calgary',
  'The University of North Carolina at Greensboro',
  'The University of Huddersfield',
  'Tarleton State University',
  'The College of Idaho',
  'Troy University',
  'Thomas More College',
  'The University of Phoenix',
  'The University of Texas Rio Grande Valley',
  'The University of Gdańsk',
  'The University of Houston – Clear Lake',
  'The University of Pittsburgh',
  'The University of California, Riverside',
  'The University of Tulsa',
  'The University of Massachusetts Amherst',
  'Toccoa Falls College',
  'The Pennsylvania State University – Abington Campus',
  'Texas Southern University',
  'Tuskegee University',
  'The Savannah College of Art and Design',
  'Temple University - Ambler',
  'The University of Illinois at Chicago',
  'The University of Sussex',
  'Temple University - Health Sciences Campus',
  'The University of Zagreb',
  'The University of Delaware',
  'The University of Warwick',
  'Thomas Aquinas College',
  'The University of Maryland, College Park',
  'Tennessee Technological University',
  'The University of Massachusetts Lowell',
  'The University of Missouri-St. Louis',
  'The University of South Florida',
  'Turner Fenton Secondary School',
  'Toyota Technological Institute at Chicago',
  'Télé-Université',
  "The Master's Seminary",
  'The University of Washington',
  'Tunis El Manar University',
  'The University of Georgia',
  'The University of Nottingham',
  'The University of San Francisco',
  'The University of Wisconsin-Platteville',
  'The University of Wolverhampton',
  'The University of Pennsylvania',
  'The University of Minnesota',
  'The University of Southern Denmark',
  'The University of Surrey',
  'The University of California, Los Angeles',
  'The University of California, Santa Cruz',
  'Thomas M. Cooley Law School',
  'The American College of Financial Services',
  'Trinity Lutheran College',
  'The University of Massachusetts Dartmouth',
  'The University of Toledo',
  'The College of New Jersey',
  'The University of Sharjah',
  'The University of Kent',
  'The University of Puerto Rico, Mayagüez Campus',
  'Temple University - Harrisburg',
  'The University of Sheffield',
  'The University of Akron',
  'The University of Oregon',
  'The University of Derby',
  'The College at Brockport, SUNY',
  'Thomas Jefferson University - Philadelphia Center City',
  'Tacony Academy Charter School',
  'The University of Southampton',
  'The University of Texas at El Paso',
  'The Cooper Union for the Advancement of Science & Art',
  'The SRM University',
  'The University of Toronto',
  'Thomas Jefferson University - East Falls (formerly Philadelphia University)',
  'Thomas Jefferson University',
  'Texas Tech University',
  'The University of Ontario Institute of Technology',
  'Trinity Christian College',
  'The University of Arkansas',
  'Texas A&M University–Commerce',
  'The University of Vermont',
  'Tennessee Wesleyan College',
  'Thakur College of Engineering and Technology',
  'The Thomas More College of Liberal Arts',
  'Texas State University–San Marcos',
  'The Arts Academy at Benjamin Rush',
  'The University of Strathclyde',
  'The University of Maryland, Baltimore County',
  'The Université de Sherbrooke',
  'Texas Lutheran University',
  'Trinity University',
  'The College of Saint Rose',
  'The University of St. Gallen',
  'The University of Aberdeen',
  'Towson University',
  'University of Oklahoma–Tulsa',
  'U of T at Scarborough',
  'University of Minnesota–Duluth',
  'University of Missouri–Saint Louis',
  'Universidad Autónoma de Madrid',
  'Universidad del Valle de México',
  'University of Houston–Victoria',
  'University of Southern California',
  'University of Texas Health Science Center at Houston',
  'University of Detroit Mercy',
  'Université du Québec à Montréal',
  'Université Laval',
  'University of Queensland',
  'Universidad del Sagrado Corazón',
  'University of South Carolina',
  'University of the Ozarks',
  'Universidad Autónoma del Estado de Morelos',
  'University of Tartu',
  'University of Maine–Machias',
  'Université du Québec à Abitibi-Temiscamingue',
  'University of Saint Mary',
  'University at New Paltz, SUNY',
  'University of Minnesota–Twin Cities',
  'Utah State University',
  'Upstate Medical University',
  'University of North Dakota',
  'University of Tennessee Health Science Center',
  'University of Colorado–Colorado Springs',
  'University of Illinois–Chicago',
  'University College of the Fraser Valley',
  'University of Wisconsin–Madison',
  'University of Montana–Missoula',
  'University of California–Los Angeles',
  'University of Guam',
  'University of Mississippi',
  'University of the Arts',
  'University of Wisconsin–Stevens Point',
  'University of Maine–Presque Isle',
  'University of Oregon',
  'University of Minnesota–Crookston',
  'University of Tulsa',
  'University of North Texas',
  'Universidad del Turabo',
  'Universidad Autónoma de Tlaxcala',
  'University of North Carolina–School of the Arts',
  'University of Wisconsin–Platteville',
  'University of California–Merced',
  'University of Texas–Tyler',
  'University of California Hastings College of the Law',
  'University of North Carolina–Wilmington',
  'University of Maine–Farmington',
  'University of Northern Colorado',
  'Universidad de Guanajuato',
  'University of Washington–Bothell',
  'University of Oklahoma',
  'Université du Québec à Chicoutimi',
  'University at Orange, SUNY',
  'Universidad Centro de Estudios Cortazar',
  'University of West Georgia',
  'University of California–San Diego',
  'United States University',
  'Universidad TecMilenio',
  'University of Toronto',
  'University of New Hampshire',
  'Utica College',
  'United Theological Seminary',
  'University of Texas–Arlington',
  'University of Maine',
  'University of Minnesota–Morris',
  'Union County Magnet High School',
  'Universidad Veracruzana',
  "University of Virginia's College at Wise",
  'University of Pittsburgh–Greensburg',
  'Universitat Autònoma de Barcelona, UAB',
  'University of Colorado System',
  'University of Prince Edward Island',
  'Université de Hearst',
  'University of South Florida',
  'Universidad de Monterrey',
  'University of Texas–Austin',
  'University of Sioux Falls',
  'United Institute of Technology',
  'University System of Maryland',
  'University of Mary Washington',
  'University of Massachusetts–Boston',
  'University of Calgary',
  'University of Connecticut',
  'Utah Valley University',
  'Université de Bordeaux',
  'University of Northern Iowa',
  'United States Naval War College',
  'United States Coast Guard Academy',
  'Union Institute & University',
  'University of Basel',
  'University of Puerto Rico–Mayagüez',
  'University of Central Florida',
  'University of Colorado–Boulder',
  'Universidad Autónoma Metropolitana',
  'University of New Hampshire School of Law',
  'University of California–San Francisco',
  'University of New Mexico',
  'University of the People',
  'University of Maryland–University College',
  'University of Texas Southwestern Medical Center at Dallas',
  'University of Arkansas–Monticello',
  'University of La Verne',
  'University Campus Suffolk',
  'United States Naval Academy',
  'University of Louisiana–Monroe',
  'UNAM FES Aragón',
  'University of Wisconsin–Milwaukee',
  'University of Texas Health Science Center at Tyler',
  'Universidad Autónoma del Estado de México',
  'University of Maine School of Law',
  'University of Duisburg-Essen',
  'University of Illinois–Urbana-Champaign',
  'University of Alabama–Birmingham',
  'Uttar Pradesh Technical University',
  'University of Puerto Rico System',
  'University of Miami',
  'University of Colorado–Denver',
  'University College of the Cariboo',
  'University of Rochester',
  'University of Central Arkansas',
  'Unification Theological Seminary',
  'University of Maine–Augusta',
  'University of South Carolina System',
  'University of Baltimore',
  'University of Evansville',
  'University of Victoria',
  'University of Manitoba',
  'Université du Québec, central',
  'University of North Carolina–Charlotte',
  'University of Windsor',
  'Universidad Central de Bayamón',
  'Utkal University',
  'University of Saint Joseph',
  'University of Missouri–Kansas City',
  'Uttaranchal Institute of Technology',
  'University of North Georgia',
  'Université de Moncton',
  'Urbana High School',
  'Université de Sherbrooke',
  'University of the Southwest',
  'University of Mount Olive',
  'Urbana University',
  'University of Maine System',
  'University of Western States',
  'University of Maryland–Baltimore County',
  'University of Texas System',
  'University of Delaware',
  'University of Nebraska Medical Center',
  'Universidad Nacional Autónoma de México',
  'University of Kansas',
  'University of Northwestern Ohio',
  'University of Northern British Columbia',
  'United States Army Command & General Staff College',
  'University of Richmond',
  'University of Mobile',
  'University of Pennsylvania',
  'University of the Cumberlands',
  'University of Udaipur',
  'United States Sports Academy',
  'University of Maryland–Baltimore',
  'University of Iowa',
  'University of New Haven',
  'University of Akron',
  'United Theological Seminary of the Twin Cities',
  "University of Hawaii–West O'ahu",
  'Universidad Politécnica de Querétaro',
  'Université de Mons',
  'University of Arkansas–Little Rock',
  'University of South Alabama',
  'University of Wisconsin–Eau Claire',
  'University of the West',
  'University of Southern Indiana',
  'University of Idaho',
  'University of Massachusetts Medical School at Worcester',
  'Université du Québec à Hull',
  'University of Puerto Rico–Bayamón',
  'University of Wisconsin–Superior',
  'University of North Carolina–Asheville',
  'University College of Cape Breton',
  'University of Missouri System',
  'University of Tennessee–Martin',
  'Universidad del Desarrollo',
  'Universitat Pompeu Fabra',
  'University of Florida',
  'University of the Potomac',
  'University of Alaska System',
  'Université du Québec à Trois-Rivières',
  'University of North America',
  'University of Puerto Rico–Aguadilla',
  'Universidad de La Salle Bajío',
  'Union Graduate College',
  'University of Maryland–College Park',
  'Union County College',
  'University at Oneonta, SUNY',
  'University of Montevallo',
  'University of Texas–Brownsville',
  'University of California–Riverside',
  'University of Michigan–Flint',
  'University of Texas Medical Branch at Galveston',
  'University of West Alabama',
  'University of Nevada–Reno',
  'University of Nebraska–Kearney',
  'University of Mount Union',
  'Universidad Tecnológica de Torreón',
  'University of the Pacific',
  'University of Massachusetts–Lowell',
  'University of Michigan–Dearborn',
  'Ursinus College',
  'University of Tennessee',
  'University of Trento',
  'University of New Hampshire at Manchester',
  'University of Wisconsin–Whitewater',
  'University at Buffalo, SUNY',
  'University of Southern Maine',
  'University of Puerto Rico–Carolina',
  'University of West Florida',
  'University of the Sciences in Philadelphia',
  'University of Northwestern–Saint Paul',
  'University of Arkansas–Fort Smith',
  'University of Saskatchewan',
  'University of California–Davis',
  'University of New Orleans',
  'United States Army War College',
  'Uniformed Services University of the Health Sciences',
  'Université de Montréal',
  'University of Ottawa',
  'University of Puerto Rico–Ciencias Médicas',
  'Universidad Autónoma de Nuevo León',
  'University of Mississippi Medical Center',
  'Universidad Tecnológica de México',
  'Ursuline College',
  'University of North Alabama',
  'University of Illinois System',
  'University of California–Santa Cruz',
  'University of Białystok',
  'University of Louisiana–Lafayette',
  'Universidad Central del Caribe',
  'University of Arkansas for Medical Sciences',
  'University of Nebraska–Omaha',
  'University of Washington–Tacoma',
  'University of Phoenix',
  'Universität Zürich',
  'University at Oswego, SUNY',
  'University of Advancing Technology',
  'University of the District of Columbia',
  'University of Washington',
  'University of Southern Mississippi',
  'University of Pittsburgh–Johnstown',
  'University of Helsinki',
  'University of Cincinnati–Blue Ash',
  'University of Louisville',
  'University at Binghamton, SUNY',
  'University of California–Berkeley',
  'Universidad Politécnica de Guanajuato',
  'University of the South',
  'University of Texas of the Permian Basin',
  'University of New Brunswick, Fredericton',
  'Unity College',
  'University of Medicine & Dentistry of New Jersey',
  'Universidad del Este',
  'University of Puerto Rico–Ponce',
  'University of Hartford',
  'University of Portland',
  'University of Winnipeg',
  'University of Houston–Clear Lake',
  'University of Georgia',
  'Universidad Panamericana',
  'University of Minnesota–Rochester',
  'University of Redlands',
  'Universidad en Línea, Mexico',
  'University of Wisconsin–Parkside',
  'University of Central Missouri',
  'University of Tennessee–Chattanooga',
  'Université Sainte-Anne',
  'Universidad Tecnológica de Puebla',
  'University of Wisconsin System',
  'University of Hawaii System',
  'University of Massachusetts System',
  'University of Western Ontario',
  'University of Houston–Downtown',
  'University of Montana–Western',
  'Union Presbyterian Seminary',
  'University of British Columbia',
  'United States Military Academy',
  'University of North Carolina–Chapel Hill',
  'University of Waterloo',
  'University of Wisconsin–River Falls',
  'University of Dayton',
  'University of Tennessee System',
  'University of Texas Health Science Center at San Antonio',
  'Universidad Autónoma del Perú',
  'University of Dallas',
  'University of the Virgin Islands',
  'Unionville High School',
  'United States Merchant Marine Academy',
  'University of Michigan',
  'University of Wisconsin–Green Bay',
  'Universidad Autónoma de Baja California (UABC), Tijuana',
  'Universitat Politècnica de Catalunya',
  'University of South Carolina–Beaufort',
  'University of Science & Arts of Oklahoma',
  'University of Great Falls',
  'University of Wisconsin–La Crosse',
  'University of Charleston',
  'University of Texas M.D. Anderson Cancer Center',
  'University of Hawaii–Manoa',
  'University of Memphis',
  'University of Puget Sound',
  'University of Notre Dame',
  'University of Pittsburgh',
  'Ulster University',
  'University of Saint Francis',
  'University of Chicago',
  'University of North Carolina–Greensboro',
  'Universidad Autónoma de Coahuila',
  'University of Washington Tacoma',
  'University of South Carolina–Upstate',
  'University of South Dakota',
  'University of Alabama–Huntsville',
  'University of New Brunswick, Saint John',
  'University of Texas–San Antonio',
  'University of Illinois–Springfield',
  'University of Nebraska System',
  'Universidad Metropolitana',
  'United States Air Force Academy',
  'University of Missouri',
  'United College of Engineering and Research',
  'University of Oklahoma Health Sciences Center',
  'University of Central Oklahoma',
  'University of South Florida Sarasota–Manatee',
  'Union Theological Seminary',
  'University of Wisconsin–Stout',
  'University of Cincinnati',
  'Universidad Politecnica de Puerto Rico',
  'University of Puerto Rico–Cayey',
  'University of Puerto Rico–Humacao',
  'University of Saint Thomas',
  'University of North Florida',
  'Universität Regensburg',
  'University of Regina',
  'University of Wyoming',
  'University of Massachusetts–Amherst',
  'University of California–Irvine',
  'University of Alabama',
  'University of Hull',
  'University at Plattsburgh, SUNY',
  'University of Tampa',
  'Upper Darby High School',
  'University of Pittsburgh–Bradford',
  'University of Kansas Medical Center',
  'Union University',
  'University of California System',
  'University of Rhode Island',
  'University of Puerto Rico–Utuado',
  'University of Arizona',
  'University of Maine–Fort Kent',
  'University of Alberta',
  'Upper Canada College',
  'University of Indianapolis',
  'University of California–Santa Barbara',
  'University of San Diego',
  'University of Cincinnati Clermont College',
  'University of Arkansas System',
  'University of Maryland University College',
  'University of Roehampton',
  'University of Texas–Dallas',
  'University of Nebraska–Lincoln',
  'University of Lethbridge',
  'Universitatea Politehnica Timişoara',
  'University of the Arts - Philadelphia',
  'Universitat de Barcelona',
  'University of Denver',
  'University of Maryland–Eastern Shore',
  'University of Houston',
  'University of Valley Forge',
  'University of the Incarnate Word',
  'University of South Carolina–Aiken',
  'University of Pikeville',
  'University of Hawaii–Hilo',
  'University of Vermont',
  'University of Mary Hardin-Baylor',
  'University of Puerto Rico–Río Piedras',
  'University of Gothenburg',
  'University of San Francisco',
  'University of Guelph',
  'University of London',
  'University of Southampton',
  'University of Arkansas',
  'Upper Iowa University',
  'University at Albany, SUNY',
  'Universidad Autónoma de San Luis Potosí',
  'University of Massachusetts–Dartmouth',
  'Upper Moreland High School',
  'Universitat Oberta de Catalunya, UOC',
  'University of Sudbury',
  'University of Texas–Pan American',
  'University of North Texas Health Science Center',
  'University of Mary',
  'Université du Québec à Rimouski',
  'University of Dubuque',
  'University of North Carolina–Pembroke',
  'University of Bridgeport',
  'University of Rio Grande',
  'University of Nevada–Las Vegas',
  'University of South Florida Saint Petersburg',
  'University of the Rockies',
  'Universidad Iberoamericana',
  'Universitat Politècnica de Catalunya, UPC',
  'University of Utah',
  'University of Arkansas–Pine Bluff',
  'University of New England',
  'University of Scranton',
  'Union County Vocational-Technical Schools',
  'University of Wisconsin–Oshkosh',
  'University of Virginia',
  'University of Jamestown',
  'University of Kentucky',
  'University of Puerto Rico–Arecibo',
  'Universidad de Guadalajara',
  'University of Toledo',
  'Union College',
  'University of Texas–El Paso',
  'Universidad Interamericana de Puerto Rico',
  'Universidad Adventista de las Antillas',
  'University College London',
  'Victory University',
  'Vadodara Institute of Engineering',
  'Voorhees College',
  'Virginia Commonwealth University',
  'Valley City State University',
  'Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College',
  'Virginia Polytechnic Institute & State University',
  'Virginia Intermont College',
  'Virginia Wesleyan College',
  'Virtual High School @ PWCS',
  'Valley High School',
  'Vrije Universiteit Amsterdam',
  'VIA University College',
  'Vermont Law School',
  'Vivekananda College for BCA',
  'Vanderbilt University',
  'Vassar College',
  'Valley Christian High School',
  'Vanier College',
  'Villanova University',
  'Veer Narmad South Gujarat University',
  'Vincent Massey Secondary School',
  'Visvesvaraya Technological University',
  'Vidyakunj International School',
  'Virginia Union University',
  'Virginia Military Institute',
  'Villa Maria College',
  'Visvesvaraya National Institute of Technology',
  'Valparaiso University',
  'Vaughn College of Aeronautics & Technology',
  'Victoria University, including Emmanuel College',
  'Valencia College',
  'Veterans Memorial Early College High School',
  "Vivekanand Education Society's Institute of Technology",
  'Vellore Institute of Technology',
  'Virginia Tech',
  'Vincennes University',
  'VanderCook College of Music',
  'Viterbo University',
  'Veer Surendra Sai University of Technology, Burla',
  'VIT University',
  'Valley Forge Christian College',
  'Vermont Technical College',
  'Valdosta State University',
  'Virginia State University',
  'Victoria Park Collegiate Institute',
  'Vanguard University of Southern California',
  'Vermont College of Fine Arts',
  'Western International University',
  'Wilfrid Laurier University',
  'Wade College',
  'West Virginia State University',
  'Westfield State University',
  'Worcester Polytechnic Institute',
  'Winthrop University',
  'Western Carolina University',
  'Wisconsin Lutheran College',
  'Warren Wilson College',
  'William Woods University',
  'Western Governors University–Tennessee',
  'Wofford College',
  'Walsh College',
  'Winona State University',
  'Walsh University',
  'Walter Biddle Saul High School',
  'Westminster Theological Seminary',
  'West Scranton High School',
  'Western Kentucky University',
  'Webster University',
  'Western State Colorado University',
  'Walla Walla University',
  'Western Oregon University',
  'West Virginia University',
  'Woodbury University',
  'Whitman College',
  'Western Michigan University',
  'Warner University',
  'Westdale Secondary School',
  'Welch College',
  'Widener University',
  'Willamette University',
  'Wingate University',
  'West Virginia Wesleyan College',
  'Walt Whitman High School',
  'Wheeling Jesuit University',
  'Western Governors University–Texas',
  'Wesley College',
  'Washington University in St. Louis',
  'Western Governors University–Indiana',
  'Woodbridge High School - Woodbridge, ON',
  'Waterloo Collegiate Institute',
  'Washington Theological Union',
  'West Potomac High School',
  'Wilson College',
  'Wilmington College',
  'Webb Institute',
  'Westwood High School',
  'Washington State University–Spokane',
  'Webb Bridge Middle School',
  'Wright State University',
  'Williams College',
  'Westfield High School',
  'Westmont College',
  'Washington State University',
  'William Penn University',
  'West Windsor-Plainsboro High School South',
  'Waterloo Lutheran Seminary',
  'Wentworth Institute of Technology',
  'Washington Township High School',
  'Ward Melville High School',
  'William W. Bodine High School',
  'West Chester University of Pennsylvania',
  'Western Governors University–Missouri',
  'Washington State University–Vancouver',
  'Woodbridge High School - Woodbridge, VA',
  'Waldorf College',
  'Wesleyan College',
  'Wilmington University',
  'Western Nevada College',
  'Wartburg College',
  'Washington & Lee University',
  'Wiltshire College',
  'Won Institute of Graduate Studies',
  'West Morris Mendham High School',
  'Winston Churchill High School',
  'West Virginia University at Parkersburg',
  'Wilkes University',
  'Washington & Jefferson College',
  'Western Seminary',
  'Webber International University',
  'Wellesley College',
  'Waynesburg University',
  'Williams Baptist College',
  'Wichita State University',
  'William Carey University',
  'William Lyon Mackenzie Collegiate Institute',
  'Western State University College of Law',
  'Wilbert Tucker Woodson High School',
  'Western New Mexico University',
  'Westminster College',
  'Walnut Hill College',
  'Western New England University',
  'Williamson Free School of Mechanical Trades',
  'Washington State University–Tri-Cities',
  'West Liberty University',
  'Wiley College',
  'Wesley Theological Seminary',
  'Wake Forest University',
  'Wesleyan University',
  'Woodbridge High School - Bridgeville',
  'Western Connecticut State University',
  'Wrocław University of Economics',
  'Western Governors University–Washington',
  'Washington University in Saint Louis',
  'Western Washington University',
  'Washington and Lee University',
  'Warsaw School of Economics',
  'West Philadelphia High School',
  'Wagner College',
  'Wrocław University of Technology',
  'William L. Sayre High School',
  'Whittier College',
  'Winston-Salem State University',
  'Warsaw University of Technology',
  'Western University',
  'Weber State University',
  'Wells College',
  'William Jessup University',
  'Washington Bible College/Capital Bible Seminary',
  'World Communications Charter School',
  'Wayne State University',
  'Western Illinois University',
  'William Paterson University',
  'Westminster Seminary California',
  'Westminster School',
  'Wayne State College',
  'Wayland Baptist University',
  'Woods Hole Oceanographic Institution',
  'William Jewell College',
  'Washington College',
  'Worcester State University',
  'Walden University',
  'Watkins College of Art, Design, & Film',
  'Wittenberg University',
  'Woodbridge High School - London',
  'West Texas A&M University',
  'Washington Adventist University',
  'Western Governors University',
  'Whitworth University',
  'Wilberforce University',
  'Warner Pacific College',
  'West Chester University',
  'Woodbridge High School - Woodbridge, NJ',
  'Wisconsin School of Professional Psychology',
  'Wartburg Theological Seminary',
  'West Virginia University Institute of Technology',
  'Wheaton College',
  'Woodbridge High School - Irvine',
  'Wheelock College',
  'Wyższa Szkoła Biznesu – National-Louis University',
  'Western University of Health Sciences',
  'Wabash College',
  'Washburn University',
  'Xavier University',
  'Xavier University of Louisiana',
  'Yale-NUS College',
  'YMCA University of Science & Technology',
  'Yeshiva University',
  'York University',
  'Young Harris College',
  'York College',
  'York College, CUNY',
  'YouthBuild Philadelphia Charter School',
  'York College of Pennsylvania',
  'Yukon College',
  'Yale University',
  'Yukon',
  'Youngstown State University',
  'Zespół Szkół Nr.2 im. Jana Pawła II w Miechowie',
  'Zespół szkół nr 1 im. Stanisława Staszica w Bochni',
  'Zespół Szkół im. Jana Pawła II w Niepołomicach',
  'Zespół Szkół nr 1 im. Jana Pawła II w Przysusze',
  'Zespół Szkół Łączności, Monte Cassino 31',
  'École de technologie supérieure',
  'École Centrale Paris',
  'École nationale supérieure d’électronique, informatique, télécommunications, mathématique et mécanique de Bordeaux (ENSEIRB-MATMECA)',
  'École Polytechnique de Montréal',
];

export default Schools;

export const SchoolsLVpair = Schools.map((v) => ({ label: v, value: v }));
